const SuccessMessage = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        padding: 10,
        marginBottom: 10,
        borderRadius: 4,
        backgroundColor: "#ccffcc",
        textAlign: "center",
        color: "#008000",
      }}
    >
      {children}
    </div>
  );
};

export default SuccessMessage;
