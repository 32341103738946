import { Button } from "@material-ui/core";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
import "../Blog.css";
import ReactGA from 'react-ga4';

const BlogWhatIsCoding = ({lng}) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname});
  }, []);

  return (
    <div>
       <Helmet>
        <title>{t("What is coding?")}</title>
        <link rel="canonical" href={lng !== "en" ? `https://iscodingforyou.com/${lng}.blog-what-is-coding.html` : `https://iscodingforyou.com/blog-what-is-coding.html`} />
        <meta name="description" content={t("Coding, also known as computer programming, is the process of designing, writing, testing, and maintaining software programs...")}/>
        <meta property="og:title" key="og:title" content={t("What is coding?")} />
        <meta property="og:locale" key="og:locale" content="en_US" />
        <meta property="og:site_name" key="og:site_name" content="Is coding for you" />
        <meta property="og:url" key="og:url" content="https://iscodingforyou.com/blog-what-is-coding.html" />
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={t("Coding, also known as computer programming, is the process of designing, writing, testing, and maintaining software programs...")}
        />
        <meta
          property="og:image"
          key="og:image"
          content="https://iscodingforyou.com/blog-1.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://iscodingforyou.com/" />
        <meta name="twitter:title" content={t("What is coding?")} />
        <meta name="twitter:image" content="https://iscodingforyou.com/blog-1.png"/>
        <meta name="twitter:description" content={t("Coding, also known as computer programming, is the process of designing, writing, testing, and maintaining software programs...")} />
      </Helmet>
      <div className="blog-about">
        <div className="blog-content">
          <img src="/blog-1.png" className="blog-image" alt="What is Coding" width="649" height="527"/>
          <h1 className="home-title-en blog-title">
            {t("What is coding?")}
          </h1>
          <br/>
          <div className="blog-post">
            <h2 className="blog-h2">{t("Introduction to Coding")}</h2>

            <p>
            {t("Coding, also known as computer programming, is the process of designing, writing, testing, and maintaining software programs. These programs can range from simple web applications to complex video games or scientific simulations.")}
            </p>

            <p>
            {t("In today's digital age, coding has become an essential skill, with more and more businesses and industries relying on software and technology to run their operations. From e-commerce websites to social media apps and self-driving cars to space exploration, coding is behind everything we see and use in our daily lives.")}
            </p>

            <p>
            {t("One of the key benefits of coding is that it allows us to automate tasks and solve problems in new and innovative ways. By writing code, we can create software that can perform tasks faster and more efficiently than humans could ever do manually. We can also build software to analyze vast amounts of data, detect patterns, and make predictions to help us make better decisions.")}
            </p>

            <p>
            {t("To give you an idea of how necessary coding is in today's world, consider this: in 2020, the global software market was worth over $640 billion, and it's expected to grow even more in the coming years. This means there is a massive demand for skilled programmers, and that demand will only increase as technology evolves.")}
            </p>

            <p dangerouslySetInnerHTML={{__html: t("So, why should you learn to code? First, coding is a highly marketable skill that can open many career opportunities. According to the {{firstArg}}, the median annual salary for software developers was over $110,000 in 2020. As a result, the demand for software developers is projected to grow much faster than the average for all occupations.", {"firstArg": "<a class='blog-link' target='_blank' href='https://www.bls.gov'>U.S. Bureau of Labor Statistics</a>"})}} />

            <p>
            {t("But beyond the financial rewards, coding can also be a fulfilling and rewarding pursuit in its own right. As you learn to code, you'll gain a deeper understanding of how technology works and how you can use it to impact the world around you positively. You'll also develop problem-solving skills that can be applied in all areas of your life, not just software development.")}
            </p>

            <p>
            {t("Now that we've covered why coding is necessary let's look at how it works.")}
            </p>

            <p>
            {t("At its most basic level, coding is simply writing instructions for a computer to follow. These instructions are written in a programming language, which is a set of rules and syntax that tells the computer what to do.")}
            </p>

            <p dangerouslySetInnerHTML={{__html: t("There are many different programming languages, each with its own strengths and weaknesses. The most popular programming languages include {{Python}}, {{Java}}, {{JavaScript}}, C++, and Ruby. Each language is suited for different tasks and has its own unique syntax and structure.", {"Python": "<a class='blog-link' target='_blank' href='https://www.python.org/'>Python</a>", "Java": "<a class='blog-link' target='_blank' href='https://www.java.com/'>Java</a>", "JavaScript": "<a class='blog-link' target='_blank' href='https://www.javascript.com/'>JavaScript</a>"})}} />

            <p>
            {t("A programmer typically starts by writing code in a text editor or an integrated development environment (IDE) to create a software program. They then compile the code into a machine-readable format that the computer can understand and execute.")}
            </p>

            <p>
            {t("Once the program is compiled, the programmer can test it to ensure it works as intended. If there are any bugs or errors, they can go back and debug the code to fix the problem.")}
            </p>

            <p>
            {t("In summary, coding is the process of writing instructions for a computer to follow using a programming language. It's an essential skill in today's digital world, offering many career opportunities and personal rewards. In the next section, we'll take a closer look at some of the basic coding concepts and how they work.")}
            </p>
            <br/>
            
            <img src="/blog-101.png" className="blog-image" alt="How Coding Works" width="520" height="372"/>
            <h2 className="blog-h2">{t("How Coding Works")}</h2>

            <p>
            {t("Coding is based on a set of fundamental concepts and principles that are used to create all kinds of software programs. While there are many different programming languages, most of them share the same basic building blocks.")}
            </p>

            <p>
            {t("Let's take a closer look at some of these concepts and how they work:")}
            </p>

            <h3 className="blog-h3">
            1. {t("Algorithms")}
            </h3>

            <p>
            {t("An algorithm is a set of instructions telling a computer how to solve a problem. Algorithms can be simple or complex, depending on the task at hand.")}
            </p>

            <p>
            {t("For example, an algorithm for sorting a list of numbers might involve comparing each number to the ones next to it and swapping them if they're out of order. On the other hand, an algorithm for playing a game like chess would be much more complex, involving strategies and decision-making based on the game's current state.")}
            </p>

            <h3 className="blog-h3">
            2. {t("Variables")}
            </h3>

            <p>
            {t("In programming, a variable is a container that holds a value. Variables can be used to store all kinds of data, from numbers and strings to more complex data structures like arrays and objects.")}
            </p>

            <p>
            {t("For example, you might use a variable called 'name' to store a person's name or a variable called 'age' to store their age. You can then use these variables in your code to perform various operations, such as printing a greeting with their name and age.")}
            </p>

            <h3 className="blog-h3">
            3. {t("Functions")}
            </h3>

            <p>
            {t("A function is a block of code that performs a specific task. Functions can take inputs (called arguments) and return outputs (called return values), making them extremely versatile and reusable.")}
            </p>

            <p>
            {t("For example, write a function that calculates the average of a list of numbers or a function that alphabetically sorts a list of strings. Once you've written a function, you can call it from anywhere in your code to perform that task.")}
            </p>

            <h3 className="blog-h3">
            4. {t("Control Structures")}
            </h3>

            <p>
            {t("Control structures are used to control the flow of a program. They allow you to make decisions and repeat specific tasks based on certain conditions.")}
            </p>

            <p>
            {t("For example, an 'if' statement allows you to check whether a certain condition is true or false and perform a certain action based on the result. Likewise, a 'for' loop will enable you to repeat a specific code block several times.")}
            </p>

            <h3 className="blog-h3">
            5. {t("Syntax")}
            </h3>

            <p>
            {t("Syntax refers to the rules and structure of a programming language. Each language has its own syntax, which determines how you write and structure your code.")}
            </p>

            <p dangerouslySetInnerHTML={{__html: t("For example, in {{Python}}, you use indentation to indicate which lines of code are part of which block. In {{JavaScript}}, you use curly braces to define blocks of code.", {"Python": "<a class='blog-link' target='_blank' href='https://www.python.org/'>Python</a>", "JavaScript": "<a class='blog-link' target='_blank' href='https://www.javascript.com/'>JavaScript</a>"})}} />

            <p>
            {t("Overall, coding is based on these and other fundamental concepts that provide the building blocks for creating all kinds of software programs. While learning to code can be challenging, it's also gratifying as you can create your own software and automate tasks in new and innovative ways.")}
            </p>
            <br/>

            <h2 className="blog-h2">{t("Getting Started with Coding")}</h2>
            <p>
            {t("Now that you understand coding and how it works, you may be eager to start coding yourself. In this section, we'll explore some tips and resources for coding. We'll look at how to choose your path, find the right resources for your learning style, and start small with manageable projects. Whether you're interested in building websites, mobile apps, video games, or data analysis, we'll help you take the first steps toward achieving your coding goals.")}
            </p>
            <p className="blog-p-online-test">
              {t("If this interests you and you want to enter the world of coding, we can offer you the online test 'Is coding for you', where you can test your coding potential.")}
              {" "}
              <Link to={lng !== "en" ? `/${lng}.index.html` : `/`} title="Is coding for you" className="blog-test-link">{t("TAKE THE TEST")}{String.fromCodePoint(0x2192)}</Link>
            </p>
          </div>
          <div>
            <Link title="Blog" to={lng !== "en" ? `/${lng}.blog.html` : `/blog.html`}>
                <Button size="large" className="blog-back-button">{String.fromCodePoint(0x2190)} {t("Back to blog")}</Button>
            </Link>
          </div>
        </div>
       </div>
    </div>
  );
};

export default BlogWhatIsCoding;
