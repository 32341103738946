import { useEffect } from "react";
import "./Privacy.css";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';

const Privacy = ({lng}) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
 

  return (
     <div className="privacy">
        <Helmet>
          <title>{t("Privacy Policy")}</title>
          <link rel="canonical" href={lng !== "en" ? `https://iscodingforyou.com/${lng}.privacy.html` : `https://iscodingforyou.com/privacy.html`} />
          <meta name="description" content="At Is Coding For You, we take your privacy seriously."/>
          <link rel="alternate" href="https://iscodingforyou.com/privacy.html" hreflang="en"/>
          <link rel="alternate" href="https://iscodingforyou.com/de.privacy.html" hreflang="de"/>
          <link rel="alternate" href="https://iscodingforyou.com/es.privacy.html" hreflang="es"/>
          <link rel="alternate" href="https://iscodingforyou.com/sr.privacy.html" hreflang="sr"/>
        </Helmet>
        <div className="content-privacy">
        <h1>{t("Privacy Policy")}:</h1>
        <br/>
        <p>
        {t("At Is Coding For You, we take your privacy seriously. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website.")}
        </p>

        <h2>
        {t("What information do we collect?")}
        </h2>

        <p>
        {t("We may collect personal information from you when you use our website, including:")}
        </p>

        <ul>
          <li>
          {t("Your name")}
          </li>
          <li>
          {t("Your email address")}
          </li>
          <li>
          {t("Your IP address")}
          </li>
          <li>
          {t("Your location")}
          </li>
          <li>
          {t("Your test results")}
          </li>
        </ul>

        <p>
        {t("We may also collect information about your use of our website, including:")}
        </p>

        <ul>
          <li>
          {t("Your device type")}
          </li>
          <li>
          {t("Your browser type")}
          </li>
          <li>
          {t("Your operating system")}
          </li>
          <li>
          {t("Your internet service provider")}
          </li>
          <li>
          {t("Your clickstream data")}
          </li>
        </ul>

        <h2>
        {t("How do we use your information?")}
        </h2>

        <p>
        {t("We use your personal information to:")}
        </p>

        <ul>
          <li>
          {t("Provide you with our services, including administering the online test and providing you with your results")}
          </li>
          <li>
          {t("Improve our website and services")}
          </li>
          <li>
          {t("Respond to your inquiries and requests")}
          </li>
          <li>
          {t("Communicate with you about our services and promotions")}
          </li>
          <li>
          {t("Comply with legal obligations")}
          </li>
        </ul>

        <p>
        {t("We may also use your information for other purposes that are disclosed to you and to which you consent.")}
        </p>

        <h2>
        {t("Do we share your information?")}
        </h2>

        <p>
        {t("We may share your information with third parties, including:")}
        </p>

        <ul>
          <li>
          {t("Service providers who help us operate our website and provide our services")}
          </li>
          <li>
          {t("Government or regulatory authorities as required by law")}
          </li>
        </ul>

        <p>
        {t("We may also share your information if we believe it is necessary to protect our rights, property, or safety, or the rights, property, or safety of others.")}
        </p>

        <h2>
        {t("How do we protect your information?")}
        </h2>

        <p>
        {t("We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.")}
        </p>

        <h2>
        {t("What are your choices and rights?")}
        </h2>

        <p>
        {t("You have the right to:")}
        </p>

        <ul>
          <li>
          {t("Access and receive a copy of the personal information we hold about you")}
          </li>
          <li>
          {t("Request that we correct or delete any inaccurate or incomplete personal information we hold about you")}
          </li>
          <li>
          {t("Object to the processing of your personal information for certain purposes, including marketing")}
          </li>
          <li>
          {t("Request that we restrict the processing of your personal information in certain circumstances")}
          </li>
          <li>
          {t("Withdraw your consent at any time, if we rely on your consent to process your personal information")}
          </li>
        </ul>

        <p>
        {t("Please note that some of these rights may be subject to limitations or exceptions under applicable law.")}
        </p>

        <h2>
        {t("How can you contact us?")}
        </h2>

        <p>
        {t("If you have any questions or concerns about our Privacy Policy or practices, please contact us at support@iscodingforyou.com.")}
        </p>

        <p>
        {t("Changes to our Privacy Policy")}
        </p>

        <p>
        {t("We may update this Privacy Policy from time to time by posting a new version on our website. We encourage you to review this Privacy Policy periodically to stay informed about our information practices.")}
        </p>

        <p>
        {t("Effective Date: 27.04.2023")}
        </p>

        <p>
        {t("By using our website, you agree to the terms of this Privacy Policy.")}
        </p>
        </div>
 </div>
  );
};

export default Privacy;
