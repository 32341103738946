import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Analysis.css";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';

const Analysis = ({lng}) => {
  const { t } = useTranslation();
  const history = useHistory()
  const [enableAnalysisItem1, setEnableAnalysisItem1] = useState(false);
  const [enableAnalysisItem2, setEnableAnalysisItem2] = useState(false);
  const [enableAnalysisItem3, setEnableAnalysisItem3] = useState(false);
  const [enableAnalysisItem4, setEnableAnalysisItem4] = useState(false);
  const [enableAnalysisItem5, setEnableAnalysisItem5] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      let summaryRoute = lng !== "en" ? `/${lng}.summary.html` : `/summary.html`;
      history.push(summaryRoute);
    }, 7200);

    const timeoutId2 = setTimeout(() => {
      setEnableAnalysisItem1(true);
    }, 1200);

    const timeoutId3 = setTimeout(() => {
      setEnableAnalysisItem2(true);
    }, 2400);

    const timeoutId4 = setTimeout(() => {
      setEnableAnalysisItem3(true);
    }, 3600);

    const timeoutId5 = setTimeout(() => {
      setEnableAnalysisItem4(true);
    }, 4800);

    const timeoutId6 = setTimeout(() => {
      setEnableAnalysisItem5(true);
    }, 6000);
  
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(timeoutId2);
      clearTimeout(timeoutId3);
      clearTimeout(timeoutId4);
      clearTimeout(timeoutId5);
      clearTimeout(timeoutId6);
    };
  }, []);
 
  return (
    <div className="analysis-content">
        <Helmet>
          <title>{t("Test Analysis")}</title>
          <link rel="canonical" href={lng !== "en" ? `https://iscodingforyou.com/${lng}.analysis.html` : `https://iscodingforyou.com/analysis.html`} />
          <meta name="description" content="We are currently analyzing your responses, and your results will be available shortly."/>
        </Helmet>
        <div className="analysis">
          <h1 className="analysis-title"> {t("We are currently analyzing your responses, and your results will be available shortly!")} </h1>
          <img src="/loading.gif" className="loading-gif" alt="Analysis"/>

          <div className="analysis-list-wrap">
            <div className="analysis-list">
              <p className={!enableAnalysisItem1 ? "analysis-item-disable" : ""}>{String.fromCodePoint(0x2705)} <span className="analysis-item-label">{t("Analyzing Data")}</span></p>
              <p className={!enableAnalysisItem2 ? "analysis-item-disable" : ""}>{String.fromCodePoint(0x2705)} <span className="analysis-item-label">{t("Generate Test Result")}</span></p>
              <p className={!enableAnalysisItem3 ? "analysis-item-disable" : ""}>{String.fromCodePoint(0x2705)} <span className="analysis-item-label">{t("Personalized Report")}</span></p>
              <p className={!enableAnalysisItem4 ? "analysis-item-disable" : ""}>{String.fromCodePoint(0x2705)} <span className="analysis-item-label">{t("Career Recommendation")}</span></p>
              <p className={!enableAnalysisItem5? "analysis-item-disable" : ""}>{String.fromCodePoint(0x2705)} <span className="analysis-item-label">{t("Learning Guide")}</span></p>
            </div>
          </div>
          
        </div>
        
    </div>
  );
};

export default Analysis;
