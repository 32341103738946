import { Button, makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
import "../Blog.css";
import BasicModal from "../../../components/Modal/BasicModal"
import { useHistory } from "react-router";
import ReactGA from 'react-ga4';

const BlogIsCodingForMe = ({openModal, handleOpenModal, handleCloseModal, fetchQuestions, removeLocalStorage, lng}) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname});
  }, []);

  const useStyles = makeStyles({
    button: {
      backgroundColor: "#F49F1C",
      borderRadius: "30px",
      fontSize: "20px",
      marginBottom: "30px",
      padding: "20px 30px",
      '&:hover': {
        backgroundColor: '#030E4F',
        "& .MuiButton-label": {
          color: '#F49F1C'
        }
      }
    }
  })
  const classes = useStyles();

  const handleSubmit = async () => {
    handleCloseModal();
    const data = await fetchQuestions();

    if (Array.isArray(data)) {
      removeLocalStorage();

      let testRoute = lng !== "en" ? `/${lng}.testing.html` : `/testing.html`;
      history.push(testRoute);
    } else {
      window.scrollTo(0, 0)
      setError(true);
    }
  };

  return (
    <div>
       <Helmet>
        <title>{t("How Can I Know If Coding Is For Me?")}</title>
        <link rel="canonical" href={lng !== "en" ? `https://iscodingforyou.com/${lng}.blog-is-coding-for-me.html` : `https://iscodingforyou.com/blog-is-coding-for-me.html`} />
        <meta name="description" content={t("Are you considering learning coding, but not sure if it's for you? Don't worry, you're not alone! Many people feel...")}/>
        <meta property="og:title" key="og:title" content={t("How Can I Know If Coding Is For Me?")} />
        <meta property="og:locale" key="og:locale" content="en_US" />
        <meta property="og:site_name" key="og:site_name" content="Is coding for you" />
        <meta property="og:url" key="og:url" content="https://iscodingforyou.com/blog-is-coding-for-me.html" />
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={t("Are you considering learning coding, but not sure if it's for you? Don't worry, you're not alone! Many people feel...")}
        />
        <meta
          property="og:image"
          key="og:image"
          content="https://iscodingforyou.com/blog-2.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://iscodingforyou.com/" />
        <meta name="twitter:title" content={t("How Can I Know If Coding Is For Me?")} />
        <meta name="twitter:image" content="https://iscodingforyou.com/blog-2.png"/>
        <meta name="twitter:description" content={t("Are you considering learning coding, but not sure if it's for you? Don't worry, you're not alone! Many people feel...")} />
        <link rel="alternate" href="https://iscodingforyou.com/blog-is-coding-for-me.html" hreflang="en"/>
        <link rel="alternate" href="https://iscodingforyou.com/de.blog-is-coding-for-me.html" hreflang="de"/>
        <link rel="alternate" href="https://iscodingforyou.com/es.blog-is-coding-for-me.html" hreflang="es"/>
        <link rel="alternate" href="https://iscodingforyou.com/sr.blog-is-coding-for-me.html" hreflang="sr"/>
      </Helmet>
      <BasicModal
        handleSubmit={handleSubmit}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      />
      <div className="blog-about">
        <div className="blog-content">
          <img src="/blog-2.png" className="blog-image" alt="Is coding for me" width="634" height="444"/>
          <h1 className="home-title-en blog-title">
            {t("Is coding for me?")}
          </h1>
          <br/>
          <p className="blog-p-online-test">
              {t("Take our online test, 'Is coding for you' and ensure you have the coding potential. No coding knowledge is required for this test.")}
          </p>
          <div className="start-test-wrap">
            <Button
              className={classes.button}
              variant="contained"
              size="large"
              onClick={handleOpenModal}
            >
              {t("Start test")} {String.fromCodePoint(0x2192)}
            </Button>
          </div>
          <br/>
          <div className="blog-post">
            <p>
            {t("Are you considering learning coding but not sure if it's for you? Don't worry, and you're not alone! Many people feel intimidated by coding, especially if they have no experience in the field. However, the truth is that anyone can learn to code, and it can open up a world of opportunities.")}
            </p>
            <h2 className="blog-h2">
              {t("Here are some reasons why coding may be for you:")}
            </h2>
            <ol>
              <li>
              {t("You're Interested in Technology: If you are curious about how things work and enjoy tinkering with technology, then coding may be a perfect fit for you. Coding allows you to create your software, apps, and websites and explore existing technology's inner workings.")}
              </li>
              <li>
                {t("You Like Problem Solving: Coding is all about solving problems. You'll need to think creatively, logically, and methodically to write code that works. Coding may be an excellent fit if you enjoy puzzles and are a natural problem solver.")}
              </li>
              <li>
                {t("You Want to Create Something: Do you envision an app or website you'd like to bring to life? Coding allows you to create something from scratch, whether a simple game, a social media platform, or a tool to help people.")}
              </li>
              <li>
                {t("You Want a Career Change: Coding is a high-demand field, and skilled programmers are in high demand. Coding may offer a stable and lucrative job opportunity if you're looking for a career change.")}
              </li>
              <li>
                {t("You Want to Learn a New Skill: Learning to program can be a fun and rewarding experience. It can also help you develop valuable skills such as problem-solving, logical thinking, and attention to detail.")}
              </li>
            </ol>
            <p>
              {t("Coding may be an excellent fit if these reasons resonate with you. Don't let fear or uncertainty hold you back from learning something new. With so many online resources, it's easier than ever to get started with coding.")}
            </p>
            
            <p dangerouslySetInnerHTML={{__html: t("There are many programming languages, but take your time with the choices. Start with a beginner-friendly language like {{Python}}, {{JavaScript}}, or Ruby. Plenty of free resources online, such as tutorials, videos, and interactive coding challenges, help you get started.", {"Python": "<a class='blog-link' target='_blank' href='https://www.python.org/'>Python</a>", "JavaScript": "<a class='blog-link' target='_blank' href='https://www.javascript.com/'>JavaScript</a>"})}} />
            
            <p>
              {t("Learning coding takes time and practice, so be patient with yourself. Don't give up if you encounter challenges or setbacks. Instead, see them as opportunities to learn and grow.")}
            </p>
            <br/>
            <h2 className="blog-h2">{t("Benefits of the programmer job")}</h2>
            <p>
            {t("Coding is a rapidly growing industry and for good reason. Not only is it an exciting field that's constantly evolving, but it also offers a range of benefits for those who pursue a career in coding. In the following text, we'll explore some of the top benefits of a coding job.")}
            </p>
            <h3 className="blog-h3">
              1. {t("High demand for skilled programmers")}
            </h3>
            <p>
            {t("One of the most significant benefits of a coding job is the high demand for skilled programmers. With technology playing an increasingly important role in nearly every industry, the need for experienced programmers is expected to grow. This means job security is high, and you'll likely be able to find work quickly.")}
            </p>
            <h3 className="blog-h3">
              2. {t("Good salary and job growth potential")}
            </h3>
            <p>
            {t()}
            </p>
            <p dangerouslySetInnerHTML={{__html: t("Another significant benefit of a coding job is the good salary and job growth potential. According to the {{firstArg}}, the median annual wage for computer and information technology occupations was $91,250 in May 2020, significantly higher than for all fields. Additionally, there is expected to be an 11% growth in the employment of computer and information technology occupations from 2019 to 2029, which is much faster than the average for all occupations.", {"firstArg": "<a class='blog-link' target='_blank' href='https://www.bls.gov'>Bureau of Labor Statistics</a>"})}} />
            <h3 className="blog-h3">
              3. {t("Opportunities for remote work")}
            </h3>
            <p>
            {t("Coding jobs also offer opportunities for remote work, which is becoming increasingly popular in today's job market. Many companies are willing to hire remote programmers, allowing you to work from anywhere in the world. This can offer a great deal of flexibility and work-life balance.")}
            </p>
            <h3 className="blog-h3">
              4. {t("Continuous learning and development")}
            </h3>
            <p>
            {t("In coding, there is always something new to learn. This can be challenging and rewarding, as you'll have the opportunity to develop your skills and knowledge continually. Additionally, many resources are available for programmers to continue learning and growing in their field.")}
            </p>

            <h3 className="blog-h3">
              5. {t("Opportunities to solve complex problems")}
            </h3>
            <p>
            {t("Coding involves solving complex problems, which can be very rewarding. You'll have the opportunity to use your creativity and critical thinking skills to develop innovative solutions to real-world problems. This can be a great source of satisfaction for many programmers.")}
            </p>
            <br/>
            <p>
              {t("In conclusion, coding is a rewarding and exciting field that anyone can learn. Whether you're interested in technology, enjoy problem-solving, want to create something new, or want to learn a new skill, coding may be for you. So take the first step and start exploring the world of coding today!")}
            </p>
            <br/>
            <br/>
            <p className="blog-p-online-test">
              {t("Take our online test, 'Is coding for you' and ensure you have the coding potential.")}
            </p>
            <div className="start-test-wrap">
              <Button
                className={classes.button}
                variant="contained"
                size="large"
                onClick={handleOpenModal}
              >
                {t("Start test")} {String.fromCodePoint(0x2192)}
              </Button>
            </div>
          </div>
          <div className="start-test-wrap">
            <Link title="Blog" to={lng !== "en" ? `/${lng}.blog.html` : `/blog.html`} >
                <Button size="large" className="blog-back-button">{String.fromCodePoint(0x2190)} {t("Back to blog")}</Button>
            </Link>
          </div>
        </div>
       </div>
    </div>
  );
};

export default BlogIsCodingForMe;
