import { Button, Card, CardMedia, CardContent, Typography, CardActions} from "@material-ui/core";
import { useEffect } from "react";
import { bounceIn } from 'react-animations';
import { StyleRoot, keyframes } from 'radium';
import "./Blog.css";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
import ReactGA from 'react-ga4';

const Blog = ({lng}) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname});
  }, []);

  const styles = {
    bounceIn: {
      animation: 'x 3s',
      animationName: keyframes(bounceIn, 'bounceIn'),
      animationDelay: '0.1s',
      animationFillMode: 'forwards',
      opacity: 0
    },
  }

  return (
    <div>
      <Helmet>
        <title>{t("Coding Blog")}</title>
        <link rel="canonical" href={lng !== "en" ? `https://iscodingforyou.com/${lng}.blog.html` : `https://iscodingforyou.com/blog.html`} />
        <meta name="description" content={t("Blog about coding that is tailored to people who know nothing about coding and beginner coders.")}/>
        <meta property="og:title" key="og:title" content={t("Coding Blog")} />
        <meta property="og:locale" key="og:locale" content="en_US" />
        <meta property="og:site_name" key="og:site_name" content="Is coding for you" />
        <meta property="og:url" key="og:url" content="https://iscodingforyou.com/blog.html" />
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={t("Blog about coding that is tailored to people who know nothing about coding and beginner coders.")}
        />
        <meta
          property="og:image"
          key="og:image"
          content="https://iscodingforyou.com/blog-1-big.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://iscodingforyou.com/" />
        <meta name="twitter:title" content={t("Coding Blog")} />
        <meta name="twitter:image" content="https://iscodingforyou.com/blog-1-big.png"/>
        <meta name="twitter:description" content={t("Blog about coding that is tailored to people who know nothing about coding and beginner coders.")} />
        <link rel="alternate" href="https://iscodingforyou.com/blog.html" hreflang="en"/>
        <link rel="alternate" href="https://iscodingforyou.com/de.blog.html" hreflang="de"/>
        <link rel="alternate" href="https://iscodingforyou.com/es.blog.html" hreflang="es"/>
        <link rel="alternate" href="https://iscodingforyou.com/sr.blog.html" hreflang="sr"/>
      </Helmet>
      <div className="blog-about">
        <StyleRoot>
          <div className="blog-heading">
            <h1 className="home-title-en blog-title" style={styles.bounceIn}>
              {t("Coding")} {" "}<span className="title-coding-about">{t("Blog")}</span>
            </h1>
            <p className="home-title-3 blog-title-3">
              {t("Blog about coding that is tailored to people who know nothing about coding and beginner coders.")}
            </p>
          </div>
        </StyleRoot>
        <Card sx={{ maxWidth: 345 }} className="blog-card">
          <CardMedia
            component="img"
            alt="What is coding?"
            height="140"
            width="826"
            image="/blog-1-big.png"
          />
          <CardContent>
            <Link to={lng !== "en" ? `/${lng}.blog-what-is-coding.html` : `/blog-what-is-coding.html`} title="What is coding">
              <Typography gutterBottom variant="h3" component="h3">
                {t("What is coding?")}
              </Typography>
            </Link>
            <Typography variant="body2">
              {t("Coding, also known as computer programming, is the process of designing, writing, testing, and maintaining software programs...")}
            </Typography>
          </CardContent>
          <CardActions>
            <Link to={lng !== "en" ? `/${lng}.blog-what-is-coding.html` : `/blog-what-is-coding.html`} title="Learn More">
              <Button size="small">{t("Learn More")}</Button>
            </Link>
          </CardActions>
        </Card>
        <Card sx={{ maxWidth: 345 }} className="blog-card">
          <CardMedia
            component="img"
            alt="Is coding for me?"
            height="140"
            width="826"
            image="/blog-2-big.png"
          />
          <CardContent>
            <Link to={lng !== "en" ? `/${lng}.blog-is-coding-for-me.html` : `/blog-is-coding-for-me.html`} title="Is coding for me">
              <Typography gutterBottom variant="h3" component="h3">
                {t("Is coding for me?")}
              </Typography>
            </Link>
            <Typography variant="body2">
              {t("Are you considering learning coding, but not sure if it's for you? Don't worry, you're not alone! Many people feel intimidated by the idea of coding...")}
            </Typography>
          </CardContent>
          <CardActions>
            <Link to={lng !== "en" ? `/${lng}.blog-is-coding-for-me.html` : `/blog-is-coding-for-me.html`} title="Learn More">
              <Button size="small">{t("Learn More")}</Button>
            </Link>
          </CardActions>
        </Card>
        <Card sx={{ maxWidth: 345 }} className="blog-card">
          <CardMedia
            component="img"
            alt="How to Learn Coding?"
            height="140"
            width="826"
            image="/blog-4-big.png"
          />
          <CardContent>
            <Link to={lng !== "en" ? `/${lng}.blog-learn-coding.html` : `/blog-learn-coding.html`} title="How to Learn Coding">
              <Typography gutterBottom variant="h3" component="h3">
              { t("How to Learn Coding?")}
              </Typography>
            </Link>
            <Typography variant="body2">
              {t("Before you start coding, it's important to understand the fundamental concepts of coding. These concepts are the building blocks of all programming languages and will help you...")}
            </Typography>
          </CardContent>
          <CardActions>
            <Link to={lng !== "en" ? `/${lng}.blog-learn-coding.html` : `/blog-learn-coding.html`} title="Learn More">
              <Button size="small">{t("Learn More")}</Button>
            </Link>
          </CardActions>
        </Card>
        <Card sx={{ maxWidth: 345 }} className="blog-card">
          <CardMedia
            component="img"
            alt="How to Make a Decision Between Frontend and Backend Development?"
            height="140"
            width="826"
            image="/blog-3-big.png"
          />
          <CardContent>
            <Link to={lng !== "en" ? `/${lng}.blog-frontend-or-backend.html` : `/blog-frontend-or-backend.html`} title="Frontend or Backend Development">
              <Typography gutterBottom variant="h3" component="h3">
              { t("How to Make a Decision Between Frontend and Backend Development?")}
              </Typography>
            </Link>
            <Typography variant="body2">
              {t("If you're new to coding, you may be wondering which area of expertise to pursue: frontend or backend development. Both are important in creating software, but they have different roles and require different skill sets...")}
            </Typography>
          </CardContent>
          <CardActions>
            <Link to={lng !== "en" ? `/${lng}.blog-frontend-or-backend.html` : `/blog-frontend-or-backend.html`} title="Learn More">
              <Button size="small">{t("Learn More")}</Button>
            </Link>
          </CardActions>
        </Card>
      </div>
    </div>
  );
};

export default Blog;
