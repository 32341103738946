import { Button, Box, TextField, Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "./BasicModal.css";

const FormModal = ({ handleSubmit, openFormModal, handleCloseFormModal }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        open={openFormModal}
        onClose={handleCloseFormModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-style">
          <div className="timeline">
            <form onSubmit={handleSubmit} className="summary-form">
                <div className="summary-fields">
                    <TextField className="summary-field" id="name-field" label={t("Name")} name="name" variant="outlined" required />
                    <TextField className="summary-field" id="age-field" label={t("Age")} name="age" variant="outlined" required type="number"/>
                    <TextField className="summary-field" id="email-field" label={t("Email")} name="email" variant="outlined" type="email" required />
                </div>
                <div className="form-modal-proceed">
                    <Button
                      variant="outlined"
                      size="large"
                      type="submit"
                      style={{
                        padding: "10px 20px"
                      }}
                    >
                        {t("PROCEED")}
                    </Button>
                </div>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FormModal;
