import { Button } from "@material-ui/core";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Contact.css";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';

const Contact = ({lng}) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
 
  return (
     <div className="contact">
        <Helmet>
          <title>{t("Contact us")}</title>
          <link rel="canonical" href={lng !== "en" ? `https://iscodingforyou.com/${lng}.contact.html` : `https://iscodingforyou.com/contact.html`} />
          <meta name="description" content="Email: support@iscodingforyou.com"/>
          <link rel="alternate" href="https://iscodingforyou.com/contact.html" hreflang="en"/>
          <link rel="alternate" href="https://iscodingforyou.com/de.contact.html" hreflang="de"/>
          <link rel="alternate" href="https://iscodingforyou.com/es.contact.html" hreflang="es"/>
          <link rel="alternate" href="https://iscodingforyou.com/sr.contact.html" hreflang="sr"/>
        </Helmet>
        <h1 className="home-title-en"> {t("Contact us")} </h1>
         <div className="contact-title">
            <span>{t("Email")} {": "}</span> <a href = "mailto: support@iscodingforyou.com">support@iscodingforyou.com</a>
         </div>
             
        <Link to={lng !== "en" ? `/${lng}.index.html` : `/`} title="Home page" className="home-link">
          <Button
            style={{
              border: "3px solid #030E4F",
              backgroundColor: "#FFD99F",
              color: "#030E4F",
              marginTop: "30px",
              padding: "15px 30px",
              maxWidth: "250px",
              margin: "auto",
              fontWeight: "bold"
            }}
            variant="text"
            size="medium"
            >
            {t("Home page")}
          </Button>
        </Link>
 </div>
  );
};

export default Contact;
