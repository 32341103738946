import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./Summary.css";
import { bounceIn, fadeIn, fadeInUp, fadeInRight, lightSpeedIn, fadeInLeft } from 'react-animations';
import { StyleRoot, keyframes } from 'radium';
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import PaymentForm from "../../components/PaymentForm/PaymentForm"
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import { format } from 'date-fns';
import { enUS, es, de,  srLatn } from 'date-fns/locale';
import { Typography, Accordion, AccordionSummary, AccordionDetails} from "@material-ui/core";


const Summary = ({ lng }) => {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [price, setPrice] = useState("");
    const [oldPrice, setOldPrice] = useState("");
    const [formattedDate, setFormattedDate] = useState('');
    // const [time, setTime] = useState(360);

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })

    useEffect( async () => {
        let testId = localStorage.getItem("testId");
        if (testId) {
            const response  = await axiosInstance.get("api/config");
            setStripePromise(loadStripe(response.data.publishableKey));
        }

        let localeDate = enUS;
        if (i18n.language === "es") {
            localeDate = es;
        }
        if (i18n.language === "de") {
            localeDate = de;
        }
        if (i18n.language === "sr") {
            localeDate = srLatn;
        }

        let discountTime = localStorage.getItem("discount-time");
        let formatted = "";
        if (discountTime) {
            const futureDate = new Date(parseInt(discountTime));
            const currentDateTime = new Date();
            const currentDateTimeStamp = currentDateTime.getTime();
            if (parseInt(discountTime) < currentDateTimeStamp) {
                const futureDateTime = new Date(currentDateTime.setDate(currentDateTime.getDate() + 1));
                formatted = format(futureDateTime, 'MMMM do', { locale: localeDate });    
            } else {
                formatted = format(futureDate, 'MMMM do', { locale: localeDate });    
            }
        } else {
            const currentDate = new Date();
            const futureDate = new Date(currentDate.setDate(currentDate.getDate() + 3));
            localStorage.setItem('discount-time', JSON.stringify(futureDate.getTime()));
            formatted = format(futureDate, 'MMMM do', { locale: localeDate });    
        }
       
        setFormattedDate(formatted);
    }, []);
    
    useEffect( async () => {
        let testId = localStorage.getItem("testId");
        if (testId) {
            const response = await axiosInstance.post("api/create-payment-intent", {
                testId: testId
            });
    
            setClientSecret(response.data.clientSecret);
            setPrice(response.data.price);
            setOldPrice(response.data.oldPrice)
        }
    }, []);


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname});
        let name = JSON.parse(localStorage.getItem("customerName"));
        setCustomerName(name);

        let email = JSON.parse(localStorage.getItem("customerEmail"));
        setCustomerEmail(email)
    }, []);

    const resultPage = () => {
        localStorage.removeItem('currQues');
        localStorage.removeItem('logicalQuestionLength');
        localStorage.removeItem('personalityQuestionLength');
        localStorage.removeItem('currQuesLogical');
        localStorage.removeItem('currQuesPersonality');
        localStorage.removeItem('currQuesEnglish');
        localStorage.removeItem('completeLogicalQuestion');
        localStorage.removeItem('completePersonalityQuestion');

        let resultRoute = lng !== "en" ? `/${lng}.result.html` : `/result.html`;
        history.push(resultRoute);
    };

    const showTime = (time) => {
        if(time >= 60) {
          var minutes = Math.floor(time / 60);
          var seconds = time - minutes * 60;
          if (minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds < 10) {
            seconds = "0" + seconds
          }
          return minutes + ":" + seconds
        }
        if (time < 10) {
          return "00:0" + time;
        }
        return "00:" + time;
    };

    const styles = {
        bounceIn: {
          animation: 'x 3s',
          animationName: keyframes(bounceIn, 'bounceIn'),
          animationDelay: '0.1s',
          animationFillMode: 'forwards',
          opacity: 0
        },
        fadeIn: {
          animation: 'x 1.5s',
          animationName: keyframes(fadeIn, 'fadeIn'),
          animationDelay: '0.8s',
          animationFillMode: 'forwards',
          opacity: 0
        },
        fadeInUp: {
            animation: 'x 1s',
            animationName: keyframes(fadeInUp, 'fadeInUp'),
            animationDelay: '0.8s',
            animationFillMode: 'forwards',
            opacity: 0
        },
        fadeInRight: {
            animation: 'x 1.2s',
            animationName: keyframes(fadeInRight, 'fadeInRight'),
            animationDelay: '0.8s',
            animationFillMode: 'forwards',
            opacity: 0
        },
        fadeInLeft: {
            animation: 'x 1.2s',
            animationName: keyframes(fadeInLeft, 'fadeInLeft'),
            animationDelay: '0.8s',
            animationFillMode: 'forwards',
            opacity: 0
        },
        pulse: {
            animation: 'x 1.5s',
            animationName: keyframes(fadeIn, 'fadeIn'),
            animationDelay: '2.5s',
            animationFillMode: 'forwards',
            opacity: 0
        },
        flash: {
            animation: 'x 2s',
            animationName: keyframes(lightSpeedIn, 'lightSpeedIn'),
            animationDelay: '1.3s',
            animationFillMode: 'forwards',
            opacity: 0
        }
    };

    const calculateDiscountPercentage = (originalPrice, discountedPrice) => {
        if (originalPrice <= 0 || discountedPrice <= 0) {
          return 0; // Prevent division by zero
        }
      
        const discountAmount = originalPrice - discountedPrice;
        const discountPercentage = (discountAmount / originalPrice) * 100;
      
        return discountPercentage;
      }

    return (
        <div>
            <Helmet>
                <title>{t("Checkout")}</title>
                <link rel="canonical" href={lng !== "en" ? `https://iscodingforyou.com/${lng}.summary.html` : `https://iscodingforyou.com/summary.html`} />
                <meta name="description" content="Discover your coding potential and shape your future!"/>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <div className="summary-content">
                <StyleRoot>
                <div className="summary-description">
                    <h1 className="summry-text" style={styles.bounceIn}>{t("Well done")}{", "} <span className="customer-name">{customerName}</span></h1>
                    <div className="summary-description-sections-2">
                       <div className="subtitle-wrap subtitle-wrap-2" style={styles.fadeInLeft}>
                            <img src="/future-left.png" className="" alt="future-left" width="50" height="50"/>
                            <span className="summry-text-5">{t("Discover your coding potential and shape your future!")}</span>
                            <img src="/future-right.png" className="" alt="future-right" width="50" height="50"/>
                        </div>
                        <div style={styles.fadeInRight} className="trophy-wrap-2">
                                <img src="/trophy.png" className="" alt="trophy" width="80" height="auto"/>
                            <div className="trophy-text-wrap">
                                <p className="summry-text-5 summry-text-52">{t("Your test is complete, but the best part is yet to come!")}</p>
                            </div>
                        </div>
                        <div className="summary-cards summary-cards-1">
                            <div className="summary-card summary-card-2 summary-card-21" style={styles.fadeInLeft}>
                                <img src="/trust.png" className="new-des-img" alt="Trusted By Over 50,000 People" width="60" height="60"/>
                                <p className="new-desc">{t("Trusted By Over 50,000 People")}</p>
                            </div>
                            <div className="summary-card summary-card-2" style={styles.fadeInRight}>
                                <img src="/results1.png" className="new-des-img" alt="Unique And Highly Accurate Results" width="60" height="60"/>
                                <p className="new-desc">{t("Unique And Highly Accurate Results")}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={styles.pulse}>
                    <img src="/down1.png" className="" alt="down"/>
                </div>
                <div className="payment-section">
                    <div className="summary-list-wrap summary-list-wrap-1" style={styles.fadeIn}>
                        <p className="what-you-get">{t("What You Get?")}</p>
                        <ul className="summary-list">
                            <li>
                                <div className="summary-list-item-wrap">
                                    <p className="summary-list-item">{t("Test Result:")}</p>
                                    <p className="summary-list-item-2">{t("Instantly see the results of your test to understand your potential and current level.")}</p>
                                </div>
                            </li>
                            <li>
                                <div className="summary-list-item-wrap">
                                    <p className="summary-list-item">{t("Personalized Report:")}</p>
                                    <p className="summary-list-item-2">{t("A detailed report from our experts, highlighting your strengths, areas for improvement, and concrete steps for the future.")}</p>

                                </div>
                            </li>   
                            <li>
                                <div className="summary-list-item-wrap">
                                    <p className="summary-list-item">{t("Career Recommendation:")}</p>
                                    <p className="summary-list-item-2">{t("Suggestion for a potential career path in the programming world, such as Full-Stack Developer, Frontend Developer, QA Tester, or Backend Developer.")}</p>
                                </div>
                            </li>
                            <li>
                                <div className="summary-list-item-wrap">
                                    <p className="summary-list-item">{t("Personalized Learning Guide:")}</p>
                                    <p className="summary-list-item-2">{t("Personalized learning steps for the recommended position, saving time and money on the wrong courses.")}</p>
                                    
                                </div>
                            </li>                              
                            <li>
                                <div className="summary-list-item-wrap">
                                    <p className="summary-list-item">{t("Recommended Course:")}</p>
                                    <p className="summary-list-item-2">{t("A recommended course for the position that suits you best, with an additional discount.")}</p>
                                </div>
                            </li>                           
                           
                            <li>
                                <div className="summary-list-item-wrap">
                                    <p className="summary-list-item">{t("Empowerment in the Coding World:")}</p>
                                    <p className="summary-list-item-2">{t("Unlock your potential and gain confidence for the first step in your coding career.")}</p>
                                </div>
                            </li>                          
                        </ul>
                       
                    </div>
                    <div style={styles.pulse}>
                        <img src="/down1.png" className="" alt="down"/>
                    </div>
                    <div className="summary-list-wrap summary-list-wrap-2" style={styles.fadeIn}>
                        <div className="summary-price-wrap">
                            <img src="/discount-icon2.png" className="discount-image" alt="discount image" width={80} height={80}/>
                             {i18n.language === "en" ? (
                                    <>
                                        {formattedDate && (
                                            <p className="discount-label">{t("This offer is valid until")}{" "}{formattedDate}</p>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <p className="discount-label">{t("This offer is valid for the first 100 users")}</p>
                                    </>
                                )}
                            <div className="discount-section-2"> 
                                <div className="all-inclusive-wrap">
                                    <p className={`all-inclusive all-inclusive-${lng}`}>{t("Once Off All")}</p>
                                    <p className={`all-inclusive all-inclusive-${lng}`}>{t("Inclusive Price")}</p>
                                </div>
                                {price && (
                                    <div> 
                                        <span className="old-price">${oldPrice}</span> <div className="price-wrap"><span className="price">${price}</span> <span className="price-point">.00</span></div>
                                    </div>
                                )}
                            </div>
                           
                        </div>
                    </div>
                    <div className="action-label-wrap">
                        <p className="action-label bold-text">{t("Unlock your potential now!")}</p>
                        <p className="action-label">{t("Click 'GET RESULT' and start your adventure in the coding world")}</p>
                    </div>

                    {clientSecret && stripePromise && (
                        <div className="payment-form">
                            <p className="payment-details">{t("Payment Details")}</p>

                            {/* <Accordion>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="why-to-pay-parent"
                                >
                                    <h3 className="why-to-pay">{t("Why do I have to pay for my results?")}</h3>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {t("The only way to accurately assess a person's IQ score is through sophisticated testing systems, developed by behavioral and data scientists. Normally these cost $400-500 and take over 24 hours to process. Our test offers accurate and instant results, for much less")}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion> */}

                            <Elements stripe={stripePromise} options={{ clientSecret }}>
                                <PaymentForm
                                    lng={lng}
                                    customerName={customerName}
                                    customerEmail={customerEmail}
                                />
                            </Elements>
                        </div>
                        
                    )}
                </div>
                </StyleRoot>

                <div className="summary-cards">
                    <div className="summary-card">
                        <img src="/secure.svg" className="" alt="Safe and Secure"/>
                        <p>{t("Safe and Secure")}</p>
                        <p className="summary-card-text">{t("We never store your card details, ensuring the security of your information and preserving your privacy with utmost care.")}</p>
                    </div>

                    <div className="summary-card">
                        <img src="/payment-card.svg" className="" alt="One Time Payment"/>
                        <p>{t("One Time Payment")}</p>
                        <p className="summary-card-text">{t("No hidden fees or sneaky renewals. Pay once and focus on your learning journey without any distractions.")}</p>
                    </div>

                    <div className="summary-card">
                        <img src="/users2.svg" className="" alt="Trusted by 50,000+ Users"/>
                        <p>{t("Trusted by 50,000+ Users")}</p>
                        <p className="summary-card-text">{t("Discover why people trust our assessment to elevate their skills. Try 'Is Coding For You?' and unveil your coding prowess today.")}</p>
                    </div>
                </div>

                <div className="customers-wrap customers-wrap-2">
                    <img src="/our-customers.png" className="our customers" alt="our customers" width="140" height="auto"/>
                    <h3 className="customers-title">
                        {t("Hear what our users are saying:")}
                    </h3>

                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                            }}
                        modules={[Pagination]}
                        className="mySwiper"
                        breakpoints={{
                        426: {
                            slidesPerView: 2,
                        },
                        769: {
                            slidesPerView: 3,
                        }
                        }}
                    >
                        <SwiperSlide>
                            <div className="customers-review">
                                <p className="customers-review-name">{t("Carl Hegedis")}</p>
                                <div className="customers-stars">
                                    <StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/>
                                </div>
                                <p className="customers-review-text">"{t("The test gave me a clear picture of my potential and the steps to take. It was worth every penny.")}"</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="customers-review">
                                <p className="customers-review-name">Seraphina Wilde</p>
                                <div className="customers-stars">
                                    <StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/>
                                </div>
                                <p className="customers-review-text">"{t("The results inspired me and gave me a clear strategy for further learning")}"</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="customers-review">
                                <p className="customers-review-name">Alex</p>
                                <div className="customers-stars">
                                    <StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarHalfIcon style={{fill: "#ffc04d"}}/>
                                </div>
                                <p className="customers-review-text">"{t("Great test!")}"</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="customers-review">
                                <p className="customers-review-name">Alice Johnson</p>
                                <div className="customers-stars">
                                    <StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/>
                                </div>
                                <p className="customers-review-text">"{t("Great test. This is what I needed, now I know where to start and in which direction to go.")}"</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="customers-review">
                                <p className="customers-review-name">Molly</p>
                                <div className="customers-stars">
                                    <StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/>
                                </div>
                                <p className="customers-review-text">"{t("I wanted to learn frontend, and that's what the result suggested, plus I got a guide.")}"</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="customers-review">
                                <p className="customers-review-name">Emily T.</p>
                                <div className="customers-stars">
                                    <StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarHalfIcon style={{fill: "#ffc04d"}}/>
                                </div>
                                <p className="customers-review-text">"{t("The test clarified my potential and steps to take. Totally worth it!")}"</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="customers-review">
                                <p className="customers-review-name">Jacob</p>
                                <div className="customers-stars">
                                    <StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/>
                                </div>
                                <p className="customers-review-text">"{t("Incredibly insightful for beginners. Confirmed my interest and provided valuable recommendations.")}"</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="customers-review">
                                <p className="customers-review-name">Luka</p>
                                <div className="customers-stars">
                                    <StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/>
                                </div>
                                <p className="customers-review-text">"{t("Exceeded expectations. Quick results and expert advice boosted my confidence.")}"</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="customers-review">
                                <p className="customers-review-name">Olivia</p>
                                <div className="customers-stars">
                                    <StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/><StarIcon style={{fill: "#ffc04d"}}/>
                                </div>
                                <p className="customers-review-text">"{t("Invaluable feedback. Understand my potential and actionable steps for improvement.")}"</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Summary;
