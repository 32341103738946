import { useEffect } from "react";
import "./NotFound.css";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';

const NotFound = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
 
  return (
    <div>
      <Helmet>
        <title>404: Page Not Found.</title>
        <meta name="description" content="Oops! You seem to be lost."/>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <div className="notFound-content">
        <h1 className="home-title-en notFound-404"> {t("404: Page Not Found.")} </h1>
        <div className="notFound-title">
            {t("Oops! You seem to be lost.")}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
