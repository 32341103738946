import { useElements, useStripe, PaymentElement } from "@stripe/react-stripe-js"
import {Button } from "@material-ui/core";
import axios from "axios"
import React, { useState } from 'react'
import "./PaymentForm.css";
import { useTranslation } from "react-i18next";

const PaymentForm = ({ lng, customerName, customerEmail }) => {
    const [success, setSuccess ] = useState(false)
    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const stripe = useStripe()
    const elements = useElements()
    const { t } = useTranslation();

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const handleSubmit = async (e) => {
      e.preventDefault();
      let testId = localStorage.getItem("testId");

      const data = {
        email: e.target.email.value,
        name: e.target.name.value,
        testId: testId
      }
      
      if ((customerName !== data.name || customerEmail !== data.email) && testId) {
        try {
          const response = await axiosInstance.post("api/updateCustomerData", data);

          if (response) {
            window.localStorage.setItem('customerEmail', JSON.stringify(data.email));
            window.localStorage.setItem('customerName', JSON.stringify(data.name));
            if (!stripe || !elements) {
              // Stripe.js has not yet loaded.
              // Make sure to disable form submission until Stripe.js has loaded.
              return;
            }
        
            setIsProcessing(true);
      
            let resultRoute = lng !== "en" ? `${lng}.result.html` : `result.html`;
      
            const { error } = await stripe.confirmPayment({
              elements,
              confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${window.location.origin}/${resultRoute}`,
              },
            });
        
            if (error.type === "card_error" || error.type === "validation_error") {
              setMessage(error.message);
            } else {
              // setMessage("An unexpected error occured.");
              setMessage(error.message);
            }
        
            setIsProcessing(false);
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
    
        setIsProcessing(true);
  
        let resultRoute = lng !== "en" ? `${lng}.result.html` : `result.html`;
  
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: `${window.location.origin}/${resultRoute}`,
          },
        });
    
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          // setMessage("An unexpected error occured.");
          setMessage(error.message);
        }
    
        setIsProcessing(false);
      }
    };

    return (
      <form id="payment-form" onSubmit={handleSubmit}>
        <div className="user-information-form-wrap">
          <div className="user-form-section">
            <img src="/user-svg.svg" className="user-svg" alt="User svg" width="16" height="16"/>
            <p className="user-icon-text">{t("User")}</p>
          </div>
          <div className="user-information-form">
            <div className="user-form-name">
              <label htmlFor="user-information-name">{t("Name")}</label>
              <input className="user-information-input user-information-input-name" id="user-information-name" variant="outlined" name="name" required defaultValue={customerName || ''}/>
            </div>
          
            <div className="user-form-email">
              <label htmlFor="user-information-email">{t("Email")}</label>
              <input className="user-information-input" id="user-information-email" variant="outlined" type="email" name="email" required defaultValue={customerEmail || ''} />
            </div>
          </div>
        </div>
        
        <PaymentElement id="payment-element" options={{layout: {type: "accordion",radios: true}}}/>

        <Button
            style={{
                backgroundColor: "#030E4F",//f7bc60
                color: "#F49F1C",//030E4F
                borderRadius: "5px",
                fontSize: "20px",
                marginTop: "20px",
                alignSelf: "center",
                padding: "15px 30px",
                width: "50%"
            }}
                variant="contained"
                size="large"
                id="submit"
                type="submit"
                className="get-result-button"
                disabled={isProcessing || !stripe || !elements}
            >
              {isProcessing ? t("Processing ... ") : t("Get Result")}
        </Button>
        {message && <div className="payment-message">{message}</div>}

        {/* Show any error or success messages */}
      </form>
    )
};

export default PaymentForm;
