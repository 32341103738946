export function removeLocalStorage() {
  localStorage.removeItem('currQues');
  localStorage.removeItem('logicalQuestionLength');
  localStorage.removeItem('personalityQuestionLength');
  localStorage.removeItem('currQuesLogical');
  localStorage.removeItem('currQuesPersonality');
  localStorage.removeItem('currQuesEnglish');
  localStorage.removeItem('completeLogicalQuestion');
  localStorage.removeItem('completePersonalityQuestion');
  localStorage.removeItem('timer');

  localStorage.removeItem('personalityQuestionLength');
  localStorage.removeItem('answers');
  localStorage.removeItem('englishQuestionLength');
  localStorage.removeItem('customerEmail');
  localStorage.removeItem('customerName');
  localStorage.removeItem('testId');
  localStorage.removeItem('sendFbEvent');
}
