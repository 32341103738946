import React from "react";
import "./index.css";
import App from "./App";
import "./i18n";
import { HelmetProvider } from 'react-helmet-async';
import { hydrate, render } from "react-dom";

const APP = (
  <HelmetProvider>
    <App />
  </HelmetProvider>
);
 
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}
