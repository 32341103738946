import { CircularProgress, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import Question from "../../components/Question/Question";
import "./Quiz.css";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "./RadialSeparators";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4';

const Quiz = ({ questions, setQuestions, answers, setAnswers, lng }) => {
  const [options, setOptions] = useState();
  const [currQues, setCurrQues] = useState(0);
  const [logicalQuestionLength, setLogicalQuestionLength] = useState();
  const [personalityQuestionLength, setPersonalityQuestionLength] = useState();
  const [englishQuestionLength, setEnglishQuestionLength] = useState();
  const [currQuesLogical, setCurrQuesLogical] = useState(1);
  const [currQuesPersonality, setCurrQuesPersonality] = useState(0);
  const [currQuesEnglish, setCurrQuesEnglish] = useState(0);
  const [completeLogicalQuestion, setCompleteLogicalQuestion] = useState(false);
  const [completePersonalityQuestion, setCompletePersonalityQuestion] = useState(false);
  // const [requireEnglish, setRequireEnglish] = useState(false);
  const [requireEnglish, setRequireEnglish] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (Array.isArray(questions)) {
      let logicalLength = 0;
      let personalityLength = 0;
      let englishQuestionLength = 0;
      questions.forEach(element => {
        if (element.group === "iq") {
          logicalLength++;
        }
        if (['personality', 'personality-qa', 'personality-managment'].includes(element.group)) {
          personalityLength++;
        }
        if (element.group === "english") {
          englishQuestionLength++;
        }
      });
      setLogicalQuestionLength(logicalLength);
      setPersonalityQuestionLength(personalityLength);
      setEnglishQuestionLength(englishQuestionLength);


      if (i18n.language !== 'en') {
        setRequireEnglish(true);
      }
    }
  }, [currQues, questions]);

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname});

    if (localStorage.getItem("currQues") != null) {
      setCurrQues(JSON.parse(window.localStorage.getItem('currQues')));
    }
    if (localStorage.getItem("logicalQuestionLength") != null && localStorage.getItem("logicalQuestionLength") != "undefined") {
      setLogicalQuestionLength(JSON.parse(window.localStorage.getItem('logicalQuestionLength')));
    }
    if (localStorage.getItem("personalityQuestionLength") != null && localStorage.getItem("personalityQuestionLength") != "undefined") {
      setPersonalityQuestionLength(JSON.parse(window.localStorage.getItem('personalityQuestionLength')));
    }
    if (localStorage.getItem("englishQuestionLength") != null && localStorage.getItem("englishQuestionLength") != "undefined") {
      setEnglishQuestionLength(JSON.parse(window.localStorage.getItem('englishQuestionLength')));
    }
    if (localStorage.getItem("currQuesLogical") != null) {
      setCurrQuesLogical(JSON.parse(window.localStorage.getItem('currQuesLogical')));
    }
    if (localStorage.getItem("currQuesPersonality") != null) {
      setCurrQuesPersonality(JSON.parse(window.localStorage.getItem('currQuesPersonality')));
    }
    if (localStorage.getItem("currQuesEnglish") != null) {
      setCurrQuesEnglish(JSON.parse(window.localStorage.getItem('currQuesEnglish')));
    }
    if (localStorage.getItem("completeLogicalQuestion") != null) {
      setCompleteLogicalQuestion(JSON.parse(window.localStorage.getItem('completeLogicalQuestion')));
    }
    if (localStorage.getItem("completePersonalityQuestion") != null) {
      setCompletePersonalityQuestion(JSON.parse(window.localStorage.getItem('completePersonalityQuestion')));
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('currQues', currQues);
  }, [currQues]);
  useEffect(() => {
    window.localStorage.setItem('logicalQuestionLength', logicalQuestionLength);
  }, [logicalQuestionLength]);
  useEffect(() => {
    window.localStorage.setItem('personalityQuestionLength', personalityQuestionLength);
  }, [personalityQuestionLength]);
  useEffect(() => {
    window.localStorage.setItem('englishQuestionLength', englishQuestionLength);
  }, [englishQuestionLength]);
  useEffect(() => {
    window.localStorage.setItem('currQuesLogical', currQuesLogical);
  }, [currQuesLogical]);
  useEffect(() => {
    window.localStorage.setItem('currQuesPersonality', currQuesPersonality);
  }, [currQuesPersonality]);
  useEffect(() => {
    window.localStorage.setItem('currQuesEnglish', currQuesEnglish);
  }, [currQuesEnglish]);
  useEffect(() => {
    window.localStorage.setItem('completeLogicalQuestion', completeLogicalQuestion);
  }, [completeLogicalQuestion]);
  useEffect(() => {
    window.localStorage.setItem('completePersonalityQuestion', completePersonalityQuestion);
  }, [completePersonalityQuestion]);

  return (
    <div className="quiz">
      <Helmet>
        <title>{t("Coding Test Questions")}</title>
        <link rel="canonical" href={lng !== "en" ? `https://iscodingforyou.com/${lng}.testing.html` : `https://iscodingforyou.com/testing.html`} />
        <meta name="description" content="Special questions on the basis of which we will determine whether you have the potential for coding."/>
        <meta name="robots" content="noindex"/>
        <link rel="alternate" href="https://iscodingforyou.com/testing.html" hreflang="en"/>
        <link rel="alternate" href="https://iscodingforyou.com/de.testing.html" hreflang="de"/>
        <link rel="alternate" href="https://iscodingforyou.com/es.testing.html" hreflang="es"/>
        <link rel="alternate" href="https://iscodingforyou.com/sr.testing.html" hreflang="sr"/>
      </Helmet>
      {questions ? (
        <>
          {/* <div className="quiz-container"> */}
          <div className="mobile-actions">
            <div className="cancel-button-wrap">
              <NavLink to={lng !== "en" ? `/${lng}.index.html` : `/`} title="Home page">
                <Button
                  style={{
                    backgroundColor: "#f2f2f2",
                  }}
                  variant="text"
                  size="large"
                  className="cancel-button"
                >
                  {t("CANCEL TEST")}
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="progress-bar">
            <div>
              <p className="test-group-name">{t("Logical test")}</p>
              <CircularProgressbarWithChildren
                value={currQuesLogical}
                valueStart={1}
                maxValue={logicalQuestionLength}
                text={completeLogicalQuestion ? String.fromCodePoint(0x2714) : `${currQuesLogical}/${logicalQuestionLength}`}
                strokeWidth={7}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textColor: '#030E4F',
                  textSize: completeLogicalQuestion ? '40px' : '20px'
                })}
              >
                <RadialSeparators
                  count={logicalQuestionLength}
                  style={{
                    background: "#fff",
                    width: "2px",
                    // This needs to be equal to props.strokeWidth
                    height: `${7}%`
                  }}
                />
              </CircularProgressbarWithChildren>
            </div>
            <div className="progress-bar-personality">
              <p className="test-group-name-personality">{t("Personality test")}</p>
              <CircularProgressbarWithChildren
                value={currQuesPersonality}
                valueStart={1}
                maxValue={personalityQuestionLength}
                text={completePersonalityQuestion ? String.fromCodePoint(0x2714) : `${currQuesPersonality}/${personalityQuestionLength}`}
                strokeWidth={7}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textColor: '#030E4F',
                  textSize: completePersonalityQuestion ? '40px' : '20px'
                })}
              >
                <RadialSeparators
                  count={personalityQuestionLength}
                  style={{
                    background: "#fff",
                    width: "2px",
                    // This needs to be equal to props.strokeWidth
                    height: `${7}%`
                  }}
                />
              </CircularProgressbarWithChildren>
            </div>

            <div className="progress-bar-personality">
              <p className="test-group-name-english">{t("English language")}</p>
              <CircularProgressbarWithChildren
                value={currQuesEnglish}
                valueStart={1}
                maxValue={englishQuestionLength}
                text={requireEnglish ? `${currQuesEnglish}/${englishQuestionLength}` : String.fromCodePoint(0x2716)}
                strokeWidth={7}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textColor: requireEnglish ? '#030E4F' : '#b84b4b',
                  // textSize: completePersonalityQuestion ? '40px' : '20px'
                  textSize: requireEnglish ? '20px' : '40px'
                })}
              >
                <RadialSeparators
                  count={requireEnglish ? englishQuestionLength : 0}
                  style={{
                    background: "#fff",
                    width: "2px",
                    // This needs to be equal to props.strokeWidth
                    height: `${7}%`
                  }}
                />
              </CircularProgressbarWithChildren>
            </div>
          </div>

          <Question
            currQues={currQues}
            setCurrQues={setCurrQues}
            questions={questions}
            options={questions[currQues].options}
            setQuestions={setQuestions}
            answers={answers}
            setAnswers={setAnswers}
            currQuesLogical={currQuesLogical}
            setCurrQuesLogical={setCurrQuesLogical}
            currQuesPersonality={currQuesPersonality}
            setCurrQuesPersonality={setCurrQuesPersonality}
            currQuesEnglish={currQuesEnglish}
            setCurrQuesEnglish={setCurrQuesEnglish}
            requireEnglish={requireEnglish}
            setCompleteLogicalQuestion={setCompleteLogicalQuestion}
            setCompletePersonalityQuestion={setCompletePersonalityQuestion}
            lng={lng}
          />
          {/* </div> */}

          <div className="riht-side-quiz">
            <div className="cancel-button-wrap">
              <NavLink to={lng !== "en" ? `/${lng}.index.html` : `/`} title="Cancel test">
                <Button
                  style={{
                    backgroundColor: "#f2f2f2"
                  }}
                  variant="text"
                  size="large"
                  className="cancel-button"
                >
                  {t("CANCEL TEST")}
                </Button>
              </NavLink>
            </div>
          </div>
        </>
      ) : (
        <div>
          <p>Loading questions</p>
          <CircularProgress
            style={{ margin: 100 }}
            color="inherit"
            size={150}
            thickness={1}
          />
        </div>
      )}
    </div>
  );
};

export default Quiz;
