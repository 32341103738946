import { useEffect, useState } from "react";
import SuccessMessage from "../../components/SuccessMessage/SuccessMessage";
import "./Affiliate.css";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import axios from "axios";
import { Button, TextField, Checkbox, FormControlLabel, FormLabel, FormGroup } from "@material-ui/core";

const Affiliate = ({lng}) => {
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  const handleSubmit = async (event) => {
    event.preventDefault()

    const data = {
      email: event.target.email.value,
      name: event.target.name.value,
      number: event.target.number.value,
      website: event.target.website.checked,
      instagram: event.target.instagram.checked,
      youTube: event.target.youTube.checked,
      linkedin: event.target.linkedin.checked,
      database: event.target.database.checked,
      other: event.target.other.checked,
      chanel1: event.target.chanel1.value,
      chanel2: event.target.chanel2.value
    }

    const response = await axiosInstance.post("api/submitAffiliate", data);

    if (response.data.success) {
      setSuccessMessage(true)
    }
  };
 
  return (
     <div className="affiliate">
        <Helmet>
          <title>{t("Unlock Your Earning Potential: Join Our Affiliate Program Today!")}</title>
          <link rel="canonical" href={lng !== "en" ? `https://iscodingforyou.com/${lng}.affiliate.html` : `https://iscodingforyou.com/affiliate.html`} />
          <meta name="description" content="Welcome to the 'Is Coding for You' Affiliate Program, where you can turn your passion for coding into a lucrative side-income. Our unique online test is designed to help individuals discover their coding potential, identify their ideal IT position, and receive personalized learning guidelines."/>
        </Helmet>
        <div className="affilate-description">
          <h1 className="home-title-en affiliate-title-en"> {t("Join Our Affiliate Program")} </h1>
          <p>{t("Welcome to the 'Is Coding for You' Affiliate Program, where you can turn your passion for coding into a lucrative side-income. Our unique online test is designed to help individuals discover their coding potential, identify their ideal IT position, and receive personalized learning guidelines. We invite you to be part of the success story.")}</p>
          <h2 className="aff-sub-title">{t("ABOUT THE PROGRAM:")}</h2>
            <p>{t("Thank you for expressing interest in the 'Is Coding for You' Affiliate Program! We're looking for enthusiastic publishers and content creators to join us in spreading the word about our unique coding test. By becoming an affiliate, you'll not only contribute to empowering individuals worldwide but also earn 30% commission on every successful sale.")}</p>
          <h2 className="aff-sub-title">{t("WHY PARTNER WITH US?")}</h2>
          <ul>
            <li className="aff-li">
              <h3>{t("Earn 30% Commission:")}</h3>
              <p>{t("Joining our affiliate program means unlocking a fantastic 30% commission on each sale. As you help others discover their coding potential, you'll be earning a substantial income along the way.")}</p>
            </li>
            <li className="aff-li">
              <h3>{t("Monthly Payouts:")}</h3>
              <p>{t("Enjoy the convenience of monthly payouts, ensuring you receive your well-deserved earnings a minimum of 30 days after each successful sale.")}</p>
            </li>
            <li className="aff-li">
              <h3>{t("Exclusive Affiliate Code:")}</h3>
              <p>{t("Get your own unique affiliate code for easy tracking. We provide you with the tools you need to monitor your success and optimize your strategy.")}</p>
            </li>
            <li className="aff-li">
              <h3>{t("30-Day Cookie Window:")}</h3>
              <p>{t("Benefit from a 30-day cookie window, maximizing your earning potential as users explore our platform and complete the test.")}</p>
            </li>
            <li className="aff-li">
              <h3>{t("Admin Panel Access:")}</h3>
              <p>{t("Gain access to our user-friendly admin panel, offering real-time insights into completed tests, tracking, and optimizing your affiliate journey.")}</p>
            </li>
          </ul>

          <h2 className="aff-sub-title">{t("READY TO APPLY?")}</h2>

          <p className="aff-join">{t("To begin your application, please answer the following:")}</p>
        </div>
        

        <form onSubmit={handleSubmit} className="affiliate-form">
          <div className="affiliate-fields">
              <TextField className="summary-field" id="name-field" label={t("Full Name")} name="name" variant="outlined" required />
              <TextField className="summary-field" id="number-field" label={t("Contact Number")} name="number" variant="outlined" required/>
              <TextField className="summary-field" id="email-field" label={t("Email")} name="email" variant="outlined" type="email" required />

              <FormLabel component="legend" className="medium-promotion">{t("Medium Of Promotion:")}</FormLabel>
              <FormGroup className="medium-checkbox">
                <FormControlLabel
                  label="Website/Blog"
                  control={<Checkbox name="website" />}
                />
                <FormControlLabel
                  label="Instagram/Facebook"
                  control={<Checkbox name="instagram" />}
                />
                <FormControlLabel
                  label="YouTube"
                  control={<Checkbox name="youTube" />}
                />
                <FormControlLabel
                  label="Linkedin"
                  control={<Checkbox name="linkedin" />}
                />
                <FormControlLabel
                  label="Database/Mailers"
                  control={<Checkbox name="database" />}
                />
                <FormControlLabel
                  label="Other"
                  control={<Checkbox name="other" />}
                />
              </FormGroup>


              <TextField className="summary-field" id="email-field" label={t("Channel/Website/Page Link1")} name="chanel1" variant="outlined" />
              <TextField className="summary-field" id="email-field" label={t("Channel/Website/Page Link2")} name="chanel2" variant="outlined" />
          </div>
          <div className="form-modal-proceed">
              <Button
                variant="outlined"
                size="large"
                type="submit"
                style={{
                  padding: "10px 20px"
                }}
              >
                  {t("SUBMIT")}
              </Button>
          </div>
        </form>
        <br/>
        {successMessage && <SuccessMessage>{t("Thank you for applying, the email has been successfully sent. We will get back to you as soon as possible.")}</SuccessMessage>}
 </div>
  );
};

export default Affiliate;
