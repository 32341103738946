import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Header.css";
import { stack as Menu } from 'react-burger-menu'
import {Select, Button, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import BasicModal from "./../Modal/BasicModal"

const Header = ({ fetchQuestions, openModal, handleOpenModal, handleCloseModal, lng, setLng, removeLocalStorage }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [testId, setTestId] = useState("");

  useEffect( async () => {
    let testIdFromLS = localStorage.getItem("testId");
    if (testIdFromLS) {
      setTestId(testIdFromLS);
    }
  }, []);

  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value
    setLng(languageValue);
    i18n.changeLanguage(languageValue);

    const path = window.location.pathname;
    if (languageValue === "en") {
      if (path.includes('index.html')) {
        history.push("/")
      } else {
        const pathSegments = window.location.pathname.split('.');
        const urlElements = pathSegments.slice(pathSegments.length - 2);
        let compontntName = urlElements[0];
        compontntName = compontntName.replace(/\//g, "");

        history.push(`/${compontntName}.${urlElements[1]}`);
      }
    } else {
      if (path === "/") {
        history.push(`/${languageValue}.index.html`)
      } else {

        const pathSegments = window.location.pathname.split('.');
        const urlElements = pathSegments.slice(pathSegments.length - 2);
        let compontntName = urlElements[0];
        compontntName = compontntName.replace(/\//g, "");

        history.push(`/${languageValue}.${compontntName}.${urlElements[1]}`);
      }
    }
  }

  const handleIsOpen = () => {
    setIsOpen(!isOpen)
  }

  const closeSideBar = () => {
    setIsOpen(false)
  }

  const handleSubmit = async (event, closeBurgerMenu = false) => {
    event.preventDefault()
    handleCloseModal();

    const data = await fetchQuestions();

    if (Array.isArray(data)) {
      removeLocalStorage();

      let testRoute = lng !== "en" ? `/${lng}.testing.html` : `/testing.html`;
      history.push(testRoute);
    } else {
      setError(true);
    }

    if (closeBurgerMenu) {
      closeSideBar();
    }
  };

  const handleOpenModalAndCloseManu = async (event) => {
    event.preventDefault()
    handleOpenModal();
    closeSideBar();
  }

  const handleCloseManu = async (event) => {
    closeSideBar();
  }

  const styles = {
    bmBurgerButton: {
      position: 'absolute',
      width: '36px',
      height: '30px',
      right: '26px',
      top: '26px'
    },
    bmBurgerBars: {
      background: '#373a47'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: '#373a47',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: 'white',
      padding: '0.8em'
    },
    bmItem: {
      display: 'inline-block',
      color: 'white'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
      left: "0px"
    }
  }

  const location = useLocation();
  const pathname = location.pathname;
  const alowTestButton = pathname === '/' || pathname.includes("index.html") || pathname.includes("coding-test.html") || pathname.includes("blog");
  const showLanguage = !pathname.includes("testing.html") && !pathname.includes("summary.html") && !pathname.includes("result.html") && !pathname.includes("analysis.html");

  return (
    // <div className="header" style={{display: alowTestButton ? 'block' : 'none'}}>
    <div className="header">
      <BasicModal
        handleSubmit={handleSubmit}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      />
      <div className="header-wrap">
        <div className="logo">
          <NavLink to={lng !== "en" ? `/${lng}.index.html` : `/`} title={t("Is coding for you?")}>
            <img src={"/logo-coding-1.png"} alt={t("Is coding for you?")} className="logo-image app-image" width="199" height="89"/>
          </NavLink>
        </div>

        <div className="nav-menu">
          <NavLink to={lng !== "en" ? `/${lng}.index.html` : `/`} title={t("Home")} exact activeStyle={{ borderBottom: "1px solid #030E4F" }}>
            <Button
              variant="text"
              size="medium"
              className="menu-button"
            >
              {t("Home")}
            </Button>
          </NavLink>
          <NavLink to={lng !== "en" ? `/${lng}.coding-test.html` : `/coding-test.html`} title={t("About test")} exact activeStyle={{ borderBottom: "1px solid #030E4F" }}>
            <Button
              variant="text"
              size="medium"
              className="menu-button"
            >
              {t("About test")}
            </Button>
          </NavLink>
          <NavLink to={lng !== "en" ? `/${lng}.blog.html` : `/blog.html`} title={t("Blog")} exact activeStyle={{ borderBottom: "1px solid #030E4F" }}>
            <Button
              variant="text"
              size="medium"
              className="menu-button"
            >
              {t("Blog")}
            </Button>
          </NavLink>

          {testId ? (
            <NavLink to={lng !== "en" ? `/${lng}.summary.html` : `/summary.html`} title={t("Test Result")} exact activeStyle={{ borderBottom: "1px solid #030E4F" }}>
              <Button
                style={{
                  border: "3px solid #F49F1C",
                  // backgroundColor: alowTestButton ? 'white' : 'gray',//030E4F
                  color: "#030E4F",
                  display: alowTestButton ? 'block' : 'none',
                }}
                variant="outlined"
                size="medium"
                className="menu-button"
              >
                {t("Test Result")}
              </Button>
            </NavLink>
          ) : (
            <div></div>
          )}

          <Button
            style={{
              border: "3px solid #F49F1C",
              // backgroundColor: alowTestButton ? 'white' : 'gray',//030E4F
              color: "#030E4F",
              display: alowTestButton ? 'block' : 'none',
            }}
            variant="outlined"
            size="medium"
            className="menu-button"
            disabled={!alowTestButton}
            onClick={handleOpenModal}
          >
            {t("Start testing")}
          </Button>
        </div>

        {showLanguage ? (
          <div className="language-list">
            <Select
              value={lng}
              onChange={changeLanguageHandler}
              inputProps={{ 'aria-label': 'Without label' }}
              disableUnderline
            >
              <MenuItem value={"en"}><img src="/locale/flags/en.svg" alt="American Flag" className="lngFlag" width="38px" height="29.5px"/>{" "}<span className="lngTitle">English</span></MenuItem>
              <MenuItem value={"de"}><img src="/locale/flags/de.svg" alt="Flag" className="lngFlag" width="38px" height="29.5px"/>{" "}<span className="lngTitle">Deutsch</span></MenuItem>
              <MenuItem value={"es"}><img src="/locale/flags/sp.svg" alt="Flag" className="lngFlag" width="38px" height="29.5px"/>{" "}<span className="lngTitle">Español</span></MenuItem>
              <MenuItem value={"fr"} disabled><img src="/locale/flags/fr.svg" alt="Français Flag" className="lngFlag" width="38px" height="29.5px"/>{" "}<span className="lngTitle">Français</span></MenuItem>
              <MenuItem value={"sr"}><img src="/locale/flags/rs.svg" alt="Flag" className="lngFlag" width="38px" height="29.5px"/>{" "}<span className="lngTitle">Srpski</span></MenuItem>
            </Select>
          </div>
        ) : (
          <div className="language-list">
          </div>
        )}

        <div className="nav-menu-burger">
          <Menu
            isOpen={isOpen}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}
            styles={styles}
            right >
            <NavLink to={lng !== "en" ? `/${lng}.index.html` : `/`} title={t("Home")} exact className="menu-button-burger" activeStyle={{ borderBottom: "1px solid #F49F1C" }}>
              <Button
                style={{
                  minWidth: "130px",
                  color: "#F49F1C"
                }}
                variant="text"
                size="medium"
                onClick={closeSideBar}
              >
                {t("Home")}
              </Button>
            </NavLink>
            <NavLink to={lng !== "en" ? `/${lng}.coding-test.html` : `/coding-test.html`} title={t("About")} exact className="menu-button-burger" activeStyle={{ borderBottom: "1px solid #F49F1C" }}>
              <Button
                style={{
                  minWidth: "130px",
                  color: "#F49F1C"
                }}
                variant="text"
                size="medium"
                onClick={closeSideBar}
              >
                {t("About")}
              </Button>
            </NavLink>
            <NavLink to={lng !== "en" ? `/${lng}.blog.html` : `/blog.html`} title={t("Blog")} exact className="menu-button-burger" activeStyle={{ borderBottom: "1px solid #F49F1C" }}>
              <Button
                style={{
                  minWidth: "130px",
                  color: "#F49F1C"
                }}
                variant="text"
                size="medium"
                onClick={closeSideBar}
              >
                {t("Blog")}
              </Button>
            </NavLink>


            {testId ? (
              <NavLink to={lng !== "en" ? `/${lng}.summary.html` : `/summary.html`} title={t("Test Result")} exact activeStyle={{ borderBottom: "1px solid #030E4F" }}>
                <Button
                  style={{
                    display: alowTestButton ? 'block' : 'none',
                    backgroundColor: '#dfe3e6'
                  }}
                  variant="outlined"
                  size="medium"
                  className="menu-button-burger-testing"
                  onClick={(e) => handleCloseManu(e)}
                >
                  {t("Test Result")}
                </Button>
              </NavLink>
            ) : (
              <div></div>
            )}

            <Button
              style={{
                display: alowTestButton ? 'block' : 'none',
                backgroundColor: '#dfe3e6'
              }}
              variant="outlined"
              size="medium"
              className="menu-button-burger-testing"
              disabled={!alowTestButton}
              onClick={(e) => handleOpenModalAndCloseManu(e)}
            >
              {t("Start testing")}
            </Button>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Header;
