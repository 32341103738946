import axios from "axios";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Home from "./Pages/Home/Home";
import Quiz from "./Pages/Quiz/Quiz";
import About from "./Pages/About/About";
import Result from "./Pages/Result/Result";
import Summary from "./Pages/Summary/Summary";
import Contact from "./Pages/Contact/Contact";
import Affiliate from "./Pages/Affiliate/Affiliate";
import Privacy from "./Pages/Privacy/Privacy";
import Analysis from "./Pages/Analysis/Analysis";
import TermsOfUse from "./Pages/TermsOfUse/TermsOfUse";
import NotFound from "./Pages/NotFound/NotFound";
import Blog from "./Pages/Blog/Blog"
import BlogWhatIsCoding from "./Pages/Blog/Posts/BlogWhatIsCoding"
import BlogIsCodingForMe from "./Pages/Blog/Posts/BlogIsCodingForMe"
import BlogFrontendOrBackend from "./Pages/Blog/Posts/BlogFrontendOrBackend"
import BlogLearnCoding from "./Pages/Blog/Posts/BlogLearnCoding"
import ReactGA from 'react-ga4';
import { useTranslation } from "react-i18next";
import { removeLocalStorage } from "./services/MainService"
import ReactPixel from 'react-facebook-pixel';


const gaKey = process.env.REACT_APP_GA_KEY
if (gaKey) {
  ReactGA.initialize(gaKey);
}

const fbPixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID
const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init(fbPixelId, advancedMatching, options);

function App() {
  const [questions, setQuestions] = useState();
  const [answers, setAnswers] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [lng, setLng] = useState("en");

  const { t, i18n } = useTranslation();

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  useEffect(() => {
    if (localStorage.getItem("questions") != null) {
      setQuestions(JSON.parse(window.localStorage.getItem('questions')));
    }
    if (localStorage.getItem("answers") != null) {
      setAnswers(JSON.parse(window.localStorage.getItem('answers')));
    }

    const pathSegments = window.location.pathname.split('.');
    const langFromUrlWithSlash = pathSegments[0];
    const langFromUrl = langFromUrlWithSlash.substring(1);
    
    if (langFromUrl && (langFromUrl === "es" || langFromUrl === "sr" || langFromUrl === "de")) {
      i18n.changeLanguage(langFromUrl);
      setLng(langFromUrl);
    } else {
      i18n.changeLanguage("en");
      setLng("en");
    }
  }, []);

  useEffect(() => {
    document.documentElement.lang = lng;
  }, [lng]);

  useEffect(() => {
    if (answers) {
      window.localStorage.setItem('answers', JSON.stringify(answers));
    }
  }, [answers]);

  const fetchQuestions = async () => {
    try {
      const { data } = await axiosInstance.get("api/questions");

      window.localStorage.setItem('questions', JSON.stringify(data));
      setQuestions(data);
      return data;
    } catch (e) {
      console.error(e);
      return null
    }
  };

  
  const articleStructuredData = {
    "@context": "http://schema.org",
    "@type": "SoftwareApplication",
    "name": t("Is coding for you? Online Test"),
    "image": "https://iscodingforyou.com/about-image.png"
  };

  return (
    <BrowserRouter>
      <div className="app">
        <script type="application/ld+json">
          {JSON.stringify(articleStructuredData)}
        </script>
        <Header
          fetchQuestions={fetchQuestions}
          openModal={openModal}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          lng={lng}
          setLng={setLng}
          removeLocalStorage={removeLocalStorage}
        />
        <Switch>
          <Route path="/" exact>
            <Home
              fetchQuestions={fetchQuestions}
              setAnswers={setAnswers}
              openModal={openModal}
              handleOpenModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
              removeLocalStorage={removeLocalStorage}
              lng={lng}
            />
          </Route>
          <Route path="/:lang(es|de|sr).index.html">
            <Home
              fetchQuestions={fetchQuestions}
              setAnswers={setAnswers}
              openModal={openModal}
              handleOpenModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
              removeLocalStorage={removeLocalStorage}
              lng={lng}
            />
          </Route>

          <Route path="/coding-test.html">
            <About 
              fetchQuestions={fetchQuestions}
              openModal={openModal}
              handleOpenModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
              removeLocalStorage={removeLocalStorage}
              lng={lng}
            />
          </Route>
          <Route path="/:lang(es|de|sr).coding-test.html">
            <About 
              fetchQuestions={fetchQuestions}
              openModal={openModal}
              handleOpenModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
              removeLocalStorage={removeLocalStorage}
              lng={lng}
            />
          </Route>

          <Route path="/affiliate.html">
            <Affiliate
              lng={lng}
            />
          </Route>
          <Route path="/:lang(es|de|sr).affiliate.html">
            <Affiliate
              lng={lng}
            />
          </Route>

          <Route path="/contact.html">
            <Contact
              lng={lng}
            />
          </Route>
          <Route path="/:lang(es|de|sr).contact.html">
            <Contact
              lng={lng}
            />
          </Route>

          <Route path="/privacy.html">
            <Privacy
              lng={lng}
            />
          </Route>
          <Route path="/:lang(es|de|sr).privacy.html">
            <Privacy
              lng={lng}
            />
          </Route>

          <Route path="/terms-of-use.html">
            <TermsOfUse
              lng={lng}
            />
          </Route>
          <Route path="/:lang(es|de|sr).terms-of-use.html">
            <TermsOfUse
              lng={lng}
            />
          </Route>

          <Route path="/testing.html">
            <Quiz
              questions={questions}
              setQuestions={setQuestions}
              answers={answers}
              setAnswers={setAnswers}
              lng={lng}
            />
          </Route>
          <Route path="/:lang(es|de|sr).testing.html">
            <Quiz
              questions={questions}
              setQuestions={setQuestions}
              answers={answers}
              setAnswers={setAnswers}
              lng={lng}
            />
          </Route>

          <Route path="/summary.html">
            <Summary
              lng={lng}
            />
          </Route>
          <Route path="/:lang(es|de|sr).summary.html">
            <Summary
              lng={lng}
            />
          </Route>

          <Route path="/analysis.html">
            <Analysis
              lng={lng}
            />
          </Route>
         <Route path="/:lang(es|de|sr).analysis.html">
            <Analysis
              lng={lng}
            />
          </Route>

          <Route path="/result.html">
            <Result 
              lng={lng}
            />
          </Route>
          <Route path="/:lang(es|de|sr).result.html">
            <Result 
              lng={lng}
            />
          </Route>

          <Route path="/blog.html" exact>
            <Blog
              lng={lng}
            />
          </Route>
          <Route path="/:lang(es|de|sr).blog.html" exact>
            <Blog
              lng={lng}
            />
          </Route>

          <Route path="/blog-what-is-coding.html">
            <BlogWhatIsCoding
              lng={lng}
            />
          </Route>
          <Route path="/:lang(es|de|sr).blog-what-is-coding.html">
            <BlogWhatIsCoding
              lng={lng}
            />
          </Route>

          <Route path="/blog-is-coding-for-me.html">
            <BlogIsCodingForMe
              fetchQuestions={fetchQuestions}
              openModal={openModal}
              handleOpenModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
              removeLocalStorage={removeLocalStorage}
              lng={lng}
             />
          </Route>
          <Route path="/:lang(es|de|sr).blog-is-coding-for-me.html">
            <BlogIsCodingForMe
              fetchQuestions={fetchQuestions}
              openModal={openModal}
              handleOpenModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
              removeLocalStorage={removeLocalStorage}
              lng={lng}
             />
          </Route>

          <Route path="/blog-frontend-or-backend.html">
            <BlogFrontendOrBackend 
              lng={lng}
            />
          </Route>
          <Route path="/:lang(es|de|sr).blog-frontend-or-backend.html">
            <BlogFrontendOrBackend
              lng={lng}
            />
          </Route>

          <Route path="/blog-learn-coding.html">
            <BlogLearnCoding 
              lng={lng}
            />
          </Route>
          <Route path="/:lang(es|de|sr).blog-learn-coding.html">
            <BlogLearnCoding
              lng={lng}
            />
          </Route>

          <Route path="*">
            <NotFound/>
          </Route>
        </Switch>
      </div>
      <Footer 
        lng={lng}
      />
    </BrowserRouter>
  );
}

export default App;
