import { Button, makeStyles, Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails} from "@material-ui/core";

import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { fadeInRight, fadeInLeft, fadeIn, fadeInUp } from 'react-animations';
import { StyleRoot, keyframes } from 'radium';
import "./Home.css";
import BasicModal from "../../components/Modal/BasicModal"
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { useCookies } from 'react-cookie';

const Home = ({ fetchQuestions, setAnswers, openModal, handleOpenModal, handleCloseModal, removeLocalStorage, lng }) => {
  const [error, setError] = useState(false);
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['affcode']);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
    setAnswers([]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname});
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const affcodeFromURL = urlParams.get('affcode');

    if (affcodeFromURL) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 30);
      
      setCookie('affcode', affcodeFromURL, { expires: expirationDate });
    }
  }, [setCookie]);


  const handleSubmit = async () => {
    handleCloseModal();
    const data = await fetchQuestions();

    if (Array.isArray(data)) {
      removeLocalStorage();

      let testRoute = lng !== "en" ? `/${lng}.testing.html` : `/testing.html`;
      history.push(testRoute);
    } else {
      window.scrollTo(0, 0)
      setError(true);
    }
  };

  const useStyles = makeStyles({
    button: {
      backgroundColor: "#F49F1C",
      borderRadius: "30px",
      fontSize: "20px",
      marginTop: "30px",
      padding: "20px 30px",
      '&:hover': {
        backgroundColor: '#030E4F',
        "& .MuiButton-label": {
          color: '#F49F1C'
        }
      }
    },
    button2: {
      backgroundColor: "#F49F1C",
      borderRadius: "30px",
      fontSize: "20px",
      padding: "20px 30px",
      '&:hover': {
        backgroundColor: '#030E4F',
        "& .MuiButton-label": {
          color: '#F49F1C'
        }
      }
    }
  })
  const classes = useStyles()


  const styles = {
    fadeInUp: {
      animation: 'x 0.5s',
      animationName: keyframes(fadeInUp, 'fadeInUp'),
      animationDelay: '0.3s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeInRight: {
      animation: 'x 0.8s',
      animationName: keyframes(fadeInRight, 'fadeInRight')
    },
    fadeInLeft1: {
      animation: 'x 0.8s',
      animationName: keyframes(fadeInLeft, 'fadeInLeft')
    },
    fadeInLeft2: {
      animation: 'x 0.8s',
      animationName: keyframes(fadeInLeft, 'fadeInLeft'),
      animationDelay: '0.1s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeIn: {
      animation: 'x 1s',
      animationName: keyframes(fadeIn, 'fadeIn'),
      animationDelay: '0.7s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeIn2: {
      animation: 'x 1s',
      animationName: keyframes(fadeIn, 'fadeIn'),
      animationDelay: '0.8s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeIn3: {
      animation: 'x 1s',
      animationName: keyframes(fadeIn, 'fadeIn'),
      animationDelay: '0.9s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeIn4: {
      animation: 'x 1s',
      animationName: keyframes(fadeIn, 'fadeIn'),
      animationDelay: '1s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeIn5: {
      animation: 'x 1s',
      animationName: keyframes(fadeIn, 'fadeIn'),
      animationDelay: '1.1s',
      animationFillMode: 'forwards',
      opacity: 0
    }
  }

  return (
    <div>
      <Helmet>
        <title>{t("Is coding for me? Online Assesment!")}</title>
        <link rel="canonical" href={lng !== "en" ? `https://iscodingforyou.com/${lng}.index.html` : `https://iscodingforyou.com/`} />
        <meta name="description" content={t("Take our Coding Potential Test to answer the question: Is Coding for Me? Get personalized results and learning recommendations.")}/>
        <meta property="og:title" key="og:title" content={t("Is coding for me? Online Assesment!")} />
        <meta property="og:locale" key="og:locale" content="en_US" />
        <meta property="og:site_name" key="og:site_name" content={t("Is coding for me? Online Assesment!")} />
        <meta property="og:url" key="og:url" content={lng !== "en" ? `https://iscodingforyou.com/${lng}.index.html` : `https://iscodingforyou.com/`} />
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={t("Take our Coding Potential Test to answer the question: Is Coding for Me? Get personalized results and learning recommendations.")}
        />
        <meta
          property="og:image"
          key="og:image"
          content="https://iscodingforyou.com/about-image.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://iscodingforyou.com/" />
        <meta name="twitter:title" content={t("Is coding for me? Online Assesment!")} />
        <meta name="twitter:image" content="https://iscodingforyou.com/about-image.png"/>
        <meta name="twitter:description" content={t("Take our Coding Potential Test to answer the question: Is Coding for Me? Get personalized results and learning recommendations.")} />
        <link rel="alternate" href="https://iscodingforyou.com/" hreflang="en"/>
        <link rel="alternate" href="https://iscodingforyou.com/de.index.html" hreflang="de"/>
        <link rel="alternate" href="https://iscodingforyou.com/es.index.html" hreflang="es"/>
        <link rel="alternate" href="https://iscodingforyou.com/sr.index.html" hreflang="sr"/>
      </Helmet>
      <BasicModal
        handleSubmit={handleSubmit}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      />
      <div className="content">
        <div className="settings">
          <div className="settings__select">
            {error && <ErrorMessage>{t("Something went wrong. Our team is working to solve it.")}</ErrorMessage>}
            <StyleRoot>
              <h1 className={"home-title-"+i18n.language} style={styles.fadeInLeft1}>
                <span>{t("Is coding for you?")}</span>{' '}
                <span className="title-coding">{t("Online Test")}</span>
              </h1>
              <h2 className="home-title-2" style={styles.fadeInLeft2}>
                <span>{t("Test your coding potential, no experience required.")}</span>
              </h2>
              <p className="home-title-3" style={styles.fadeIn}>
                {t("Discover your coding potential by taking this test. Your first step into the coding world awaits.")}
              </p>
              <p className="home-paragraph-2" style={styles.fadeIn}>
                {t("The test consists of three parts:")}
              </p>
              <ul className="features-list">
                <li style={styles.fadeIn2}>
                  <div className="home-list-section-1">
                    <h3 className="list-task-group">{t("Problem-solving and logical test")}</h3>
                    <p className="home-sublist home-sublist-1">{t("Timed tasks that need to be solved in the shortest time possible")}</p>
                  </div>
                </li>
                <li style={styles.fadeIn3}>
                  <div className="home-list-section-1">
                    <h3 className="list-task-group">{t("Personality test")}</h3>
                    <p className="home-sublist home-sublist-1">{t("Unlock your personality traits")}</p>
                  </div>
                </li>
                <li style={styles.fadeIn4}>
                  <div className="home-list-section-1">
                    <h3 className="list-task-group">{t("English language test")}</h3>
                    <p className="home-sublist home-sublist-1">{t("In the test score results you will get the information on whether you possess the necessary level")}{' '}
                        <span>{t("or you need to improve your English")}</span>
                    </p>
                  </div>
                </li>
              </ul>
              <p className="home-paragraph-3" style={styles.fadeIn4}>
                {t("The result will be sent to your email immediately after finishing the test,")}{' '}
                <span>{t("with a detailed explanation and answer to the question")}</span>{' '}
                <span>{t("of whether coding is for you.")}</span>
              </p>

              <div style={styles.fadeInUp}>
                <div className="start-test-wrap">
                  <Button
                    className={classes.button}
                    variant="contained"
                    size="large"
                    onClick={handleOpenModal}
                  >
                    {t("Start test")} {String.fromCodePoint(0x2192)}
                  </Button>
                </div>
              </div>
            </StyleRoot>
          </div>
        </div>
        <div className="banner">
          <StyleRoot>
            <img src="/about-image.png" className="banner-image app-image" style={styles.fadeInRight} alt={t("Is coding for me?")} width="686" height="498.05"/>
          </StyleRoot>
        </div>
      </div>
      <div className="home-section-2-1">
        <div className="content-2-1" >
          {/* <p>Trusted By 130,000 People Worldwide</p>
          <p>More than 1000 tests from over 50 countries were performed this month</p> */}
          <p className="home-trusted">{t("Across more than {{firstParam}} countries, over {{secondParam}} tests were successfully conducted this month.", {"firstParam":"50", "secondParam": "1000"})}</p>
        </div>
      </div>
      <div className="home-section-2">
        <div className="content-2-new" >
          <h2 className="home-title-section-2"><span className="title-coding-why">{t("Why")}</span> {t("Our Test?")}</h2>
          <div className="home-why-section">
            <Card sx={{ maxWidth: 345 }} className="home-why-card">
              <div className="why-image">
                <img src="/home-why-51.svg" className="banner-image app-image" alt={t("Unlock Your Coding Potential")} width="120" height="120"/>
              </div>
              <CardContent>
                  <h3 className="why-card-title">
                    {t("Unlock Your Coding Potential")}
                  </h3>
                  <p className="why-card-description">
                    {t("Unveil your coding potential and explore promising opportunities. Our test helps you make right decisions about learning to code or pursuing a coding career.")}
                  </p>
              </CardContent>
            </Card>
            <Card sx={{ maxWidth: 345 }} className="home-why-card">
              <div className="why-image">
                <img src="/home-why-3.svg" className="banner-image app-image" alt={t("Discover Your Ideal IT Position")} width="120" height="120"/>
              </div>
              <CardContent>
                <h3 className="why-card-title">
                  {t("Discover Your Ideal IT Position")}
                </h3>
                <p className="why-card-description">
                  {t("Embark on a personalized IT journey with our assessment. Discover your forte—whether it's full stack, frontend, backend, or QA testing. Uncover the perfect IT position tailored just for you.")}
                </p>
              </CardContent>
            </Card>
            <Card sx={{ maxWidth: 345 }} className="home-why-card">
              <div className="why-image">
                <img src="/home-why-2.svg" className="banner-image app-image" alt={t("Get personalized learning guidelines")} width="120" height="120"/>
              </div>
              <CardContent>
                  <h3 className="why-card-title">
                    {t("Get personalized learning guidelines")}
                  </h3>
                <p className="why-card-description">
                  {t("Unlock insights tailored to enhance your coding skills with our assessment. Receive a personalized report and guidelines to navigate your unique learning journey.")}
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      <div className="section-3-people-1">
        <div className="section-3-people">
          <div className="ban-banner">
            <div className="ban-container">
              <div className="ban-container-head">
                <div className="ban-image-container">
                  <img src="/ban.jpeg" className="ban-image" alt={t("Head of Engineering & Business Development")} width="80" height="80"/>
                </div>
                <div>
                  <p className="ban-head-desc ban-head-desc-name">Ben Burmudzija</p>
                  <p className="ban-head-desc">{t("Head of Engineering & Business Development")}</p>
                </div>
              </div>
              <div>
                <p className="ban-content">
                  "{t("Coding is like singing. Anyone can sing, but not everyone is good at it. The same goes for coding. Try our test and assess your coding potential.")}"
						    </p>
              </div>
              <Button 
                variant="outlined"
                className="ban-button"
                onClick={handleOpenModal}>
                  {t("Take the Test")}
              </Button>
            </div>
          </div>
          <div className="ban-banner-empty">

          </div>
        </div>
      </div>
      <div className="home-section-3-1">
        <div className="content-3-1">
         <div className="banner-3-1">
            <img src="/result4Blur.png" className="banner-image app-image" alt={t("Take the Test")} width="348" height="457.63"/>
          </div>
          <div className="settings-3-1">
            <div className="settings-3-1-text">
              <h2 className="home-title-section-3-1">{t("Have you ever wondered")}{", "}<span className="title-coding-why"><br/>{"\""}{t("Is coding for me?")}{"\""}</span></h2>
              {/* <p className="home-p-section-3-1">
                {t("Find out with our")} {" "}<span className="bold-text">{t("test.")}</span>
              </p> */}
              <p className="home-p-section-3-1">
                {t("If you've been pondering this question, you're not alone. Many individuals, at some point, consider the exciting world of coding as a potential path. Our comprehensive test is designed to help you and provide you with personalized insights.")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section-3">
        <div className="content-3">
          <div className="settings-3">
            <div className="settings-3-text">
              <h2 className="home-title-section-2 home-title-section-3"><span className="title-coding-why">{t("What")}</span>{" "}{t("is this Test?")}</h2>
              <div className="list-group list-group-flush home-list-section-3">
                <div className="list-group-item list-group-item-light">
                  {/* {t("This is a test to determine whether coding is for you. The test is intended for beginners who wish to start learning to code. It is designed so that, your results will determine whether coding is for you and to give you specific learning guidelines or suggest an alternative path, such as QA testing.")} */}
                  <p className="what-test-paragraph">{t("This is a test aimed at helping beginners determine if coding is the right fit. It's designed in the form of a quiz to provide personalized results that guide your path forward. Your outcomes will help assess whether coding suits you and offer tailored learning recommendations or suggest alternative routes, such as QA testing.")}</p>
                  <p className="what-test-paragraph">{t("The test contains {{firstParam}} questions and we will send you the result immediately upon completion with a detailed report from our experts in the field of coding.", {"firstParam":"35"})}</p>
                </div>
                {/* <div className="list-group-item list-group-item-light">
                  {t("The test contains {{firstParam}} questions and we will send you the result immediately upon completion with a detailed report from our experts in the field of coding.", {"firstParam":"45"})}
                </div> */}
                {/* <div className="list-group-item list-group-item-light">
                  {t("The questions are from 3 areas:")}
                  <ul className="home-sublist home-sublist-language">
                    <li>
                      <p className="list-3">{t("Problem-solving and logical test")}</p>
                    </li>
                    <li>
                      <p className="list-3">{t("Personality test")}</p>
                    </li>
                    <li>
                      <p className="list-3">{t("The English language test")}</p>
                    </li>
                  </ul>
                </div>
                <div className="list-group-item list-group-item-light">
                  {t("In the test result, you will get a detailed report and answer to the question, ‘is coding for you’.")}
                </div>
                <div className="list-group-item list-group-item-light">
                  {t("This test is developed by experts from the field of programming.")}
                </div> */}
                </div>
            </div>

            <p className="home-ready-2">{t("Are you ready?")}</p>
            <Button
              className={classes.button2}
              variant="contained"
              size="large"
              onClick={handleOpenModal}
            >
              {t("Start test")} {String.fromCodePoint(0x2192)}
            </Button>
          </div>
          <div className="banner-3">
            <img src="/home-section-3.png" className="banner-image app-image" alt={t("Is coding for you?")} width="348" height="457.63"/>
          </div>
        </div>
      </div>
      <div className="home-section-5">
        <div className="content-2-new content-5">
          <h2 className="home-section-5-title">{t("Frequently Asked Questions")}</h2>
          <Accordion>
            <AccordionSummary
              expandIcon={String.fromCodePoint(8964)}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h3 className="faq-title">{t("Is coding the right fit for me?")}</h3>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t("If you feel a curiosity for problem-solving, analyzing information, and embracing challenges, coding might be the perfect fit. Our test helps you better understand your inclination towards coding.")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={String.fromCodePoint(8964)}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3 className="faq-title">{t("What if I have no prior programming experience?")}</h3>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t("Our test is designed for absolute beginners. Regardless of your past experience, it provides insights into your coding potential.")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={String.fromCodePoint(8964)}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3 className="faq-title">{t("How long does the testing take, and how do I receive the results?")}</h3>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t("Testing usually takes around 10-15 minutes. Upon completion, you'll instantly receive a detailed report assessing your coding predisposition and offering personalized recommendations.")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={String.fromCodePoint(8964)}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3 className="faq-title">{t("Can coding be a career path for someone without a technical background?")}</h3>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t("Absolutely! Coding welcomes diverse backgrounds. Many successful developers started without a technical foundation. Our test guides you on your coding journey, regardless of your initial background.")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={String.fromCodePoint(8964)}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3 className="faq-title">{t("Is programming only for young individuals, or can anyone start learning at any age?")}</h3>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t("Programming knows no age limits! Whether you're a teenager or considering a career change later in life, our online programming test evaluates your potential, making it accessible for learners of all ages.")}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div className="result-social-home">
        <h2 className="social-title">{t("Help us spread the word and let your friends take the test too")}</h2>
        <div className="social-icons">
          <a href="whatsapp://send?text=Is Coding For You. Online Test https://iscodingforyou.com">
            <img src="/social-wa.png" className="social-icon" alt="wathsapp" width="50" height="50"/>
          </a>
          <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.iscodingforyou.com" target="_blank">
            <img src="/social-fb.png" className="social-icon" alt="facebook" width="50" height="50"/>
          </a>
          <a href="https://twitter.com/intent/tweet?url=https://www.iscodingforyou.com&text=Is Coding For You. Online Test" target="_blank">
            <img src="/social-tw.png" className="social-icon" alt="tweeter" width="50" height="50"/>
          </a>
          <a href="https://www.linkedin.com/shareArticle?url=https://www.iscodingforyou.com&title=Is Coding For You. Online Test" target="_blank">
            <img src="/social-ln.png" className="social-icon" alt="linkedin" width="50" height="50"/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
