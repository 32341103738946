import { Button, Box, Modal, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "./BasicModal.css";

const BasicModal = ({ handleSubmit, openModal, handleCloseModal }) => {
  // const [error, setError] = useState(false);
  // const history = useHistory();
  const { t, i18n } = useTranslation();

  const useStyles = makeStyles({
    button: {
      backgroundColor: "#F49F1C",
      borderRadius: "18px",
      fontSize: "20px",
      padding: "18px",
      margin: "0px 30px 20px 30px",
      minWidth: "130px",
      '&:hover': {
        backgroundColor: '#030E4F',
        "& .MuiButton-label": {
          color: '#F49F1C'
        }
      }
    },
    button2: {
      borderRadius: "18px",
      fontSize: "20px",
      padding: "18px",
      margin: "0px 30px 20px 30px",
      minWidth: "130px",
      marginBottom: "20px"
    }
  })
  const classes = useStyles()

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-style">
          <div className="timeline">
            <p className="timeline-description-1">{t("Make yourself comfortable.")}</p>
            <p className="timeline-description-2">{t("This test will take about 10 minutes.")}</p>
            <p className="timeline-description-3">{t("TEST")}</p>
            <div className="outer">
              <div className="card">
                <div className="info">
                  <p className="title-timeline">1. {t("Problem-solving and logical test")}</p>
                  <p><samp className="number-of-question">{t("15")}</samp>{" "}<samp className="question-question">{t("questions")}</samp></p>
                </div>
              </div>
              <div className="card">
                <div className="info">
                  <p className="title-timeline">2. {t("Personality test")}</p>
                  <p><samp className="number-of-question">{t("20")}</samp>{" "}<samp className="question-question">{t("questions")}</samp></p>
                </div>
              </div>
              {/* <div className={i18n.language === "en" ? "card card-disable" : "card"}> */}
              <div className="card">
                <div className="info">
                  <p className="title-timeline">3. {t("The English language test")}</p>
                  {/* {i18n.language === "en" ? (
                    <p><samp className="question-question-english">{t("If you select English, this part is waived and already completed.")}</samp></p>
                  ) : ( */}
                    <p><samp className="number-of-question">{t("10")}</samp>{" "}<samp className="question-question">{t("questions")}</samp></p>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
          <div id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="start-test-wrap-modal">
              <Button
                className={classes.button2}
                variant="contained"
                size="large"
                onClick={handleCloseModal}
              >
                {t("Close")}
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="large"
                onClick={handleSubmit}
              >
                {t("Start")}{" "} {String.fromCodePoint(0x2192)}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default BasicModal;
