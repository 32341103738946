import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./Result.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import { VictoryPie, VictoryLabel } from 'victory';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

const Result = ({ lng }) => {
  const history = useHistory();
  const [score, setScore] = useState();
  const [description1, setDescription1] = useState();
  const [description2, setDescription2] = useState();
  const [description3, setDescription3] = useState();
  const [description4, setDescription4] = useState();
  const [list1, setList1] = useState();
  const [list2, setList2] = useState();
  const [list3, setList3] = useState();
  const [list4, setList4] = useState();
  const [list5, setList5] = useState();
  const [scaleData, setScaleData] = useState([]);
  const [colors, setColors] = useState();
  const [scoreLabel, setScoreLabel] = useState("0");
  const [passPersonality, setPassPersonality] = useState();
  const [resultScoreLabel, setResultScoreLabel] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);


  const { t } = useTranslation();
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        history.push('/');
        window.scrollTo(0, 0);
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname});

    let email = JSON.parse(localStorage.getItem("customerEmail"));
    let testId = localStorage.getItem("testId");

    const paymentIntent = queryParams.get('payment_intent');
    const paymentIntentClientSecret = queryParams.get('payment_intent_client_secret');

    if (email && testId && paymentIntent && paymentIntentClientSecret) {
      let params = {
        email: email,
        id: testId,
        paymentIntent: paymentIntent,
        paymentIntentClientSecret: paymentIntentClientSecret
      };

      const { data } = await axiosInstance.post("api/result", params);
      
      if (!data.success) {
        let homeRoute = lng !== "en" ? `/${lng}.index.html` : `/`;
        history.push(homeRoute);
      } else {
        setScore(data.conclusion);
        setDescription1(data.description1);
        setDescription2(data.description2);
        setDescription3(data.description3);
        setDescription4(data.description4);
        setList1(data.list1);
        setList2(data.list2);
        setList3(data.list3);
        setList4(data.list4);
        setList5(data.list5);
        setPassPersonality(data.passPersonality);
        setResultScoreLabel(data.resultScoreLabel);

        let realScore = (100 / 110) * data.scorePercentage;
        if (realScore > 100) {
          realScore = 100;
        }
        setScoreLabel(Math.round(realScore));

        let scaleData = [
          { x: 1, y: 0, label: " "},
          { x: 2, y: 0, label: " " },
          { x: 3, y: 0, label: " " },
          { x: 4, y: 0, label: " " },
          { x: 5, y: 0, label: " " },
          { x: 6, y: 0, label: " " }
        ];
        let realScoreForCalculation = Math.round(realScore);
        scaleData.forEach(el => {
          if (el.x !== 6) {
            if (realScoreForCalculation >= 20) {
              el.y = 20;
              realScoreForCalculation = realScoreForCalculation - 20;
            } else {
              el.y = realScoreForCalculation;
              realScoreForCalculation = 0;
            }
          } else {
            const unsolved = 100 - realScore;
            el.y = unsolved;
          }
        })

        setTimeout(() => {
          setScaleData(scaleData)
          setColors(["#ff6347","#ff7f50", "#ffa500", "#32cd32", "#008000", "#D0D0D0"]);
        }, 1000);
      }
    }

  }, [setScore, setDescription1, setDescription2, setDescription3, setDescription4]);

  useEffect(() => {
    try {
      let sendFbEvent = localStorage.getItem("sendFbEvent");
      if (sendFbEvent == null || sendFbEvent == "undefined" || !sendFbEvent) { 
        ReactPixel.track("Purchase", {currency: "USD", value: 14.00});
        localStorage.setItem("sendFbEvent", 1)
      }
    } catch (e) {
      console.error(e);
    }

    setScaleData([
      { x: 1, y: 0, label: " " },
      { x: 2, y: 0, label: " " },
      { x: 3, y: 0, label: " " },
      { x: 4, y: 0, label: " " },
      { x: 5, y: 0, label: " " },
      { x: 6, y: 100, label: " " },
    ])
    setColors(["gray"]);
  }, []);

  return (
    <div>
      <Helmet>
        <title>{t("THANK YOU FOR TAKING OUR TEST")}</title>
        <link rel="canonical" href={lng !== "en" ? `https://iscodingforyou.com/${lng}.result.html` : `https://iscodingforyou.com/result.html`} />
        <meta name="description" content={t("The test score result is sent to your email, and you can also see it below")}/>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <div className="result-content">
        <div className="result-container">
          <h1 className="result-main-title">{t("THANK YOU FOR TAKING OUR TEST")}</h1>
          <h2 className="result-main-title-2">{t("The test score result is sent to your email, and you can also see it below")}</h2>
          <div className="result-description-main">
            <div className="result-title">
              <p className="result-title-1">{t("THE RESULT")}:</p>
              <p className="result-title-2">{score}</p>
            </div>
          </div>


          <div className="result-description">
            <div className="details-score">
                <div className="details-score-logical">
                  <p className="test-name">{t("Logical test score")}</p>
                  <p className="test-name-result">{t(resultScoreLabel)}</p>
                  <div className="score-scale">
                    <svg width={300} height={300} viewBox='0 0 365 365'>
                      <VictoryPie
                        startAngle={-135}
                        endAngle={135}
                        data={scaleData}
                        innerRadius={100}
                        colorScale={colors}
                        animate={{ duration: 2000 }}
                        standalone={false}
                        width={360} height={360}
                      />
                      <VictoryLabel
                        textAnchor="middle" verticalAnchor="middle"
                        x={180} y={180}
                        style={{fontSize: 60}}
                        text={scoreLabel}
                      />
                    </svg>
                  </div>
                </div>
                <div className="details-score-personality">
                  <p className="test-name">{t("Personality test")}</p>
                  {passPersonality === true ? (
                      <p className="test-name-result">{t("Passed")}</p>
                    ) : (
                      <div>
                        {passPersonality === false ? (
                          <p className="test-name-result">{t("Not Passed")}</p>
                        ) : (
                          <p className="test-name-result"></p>
                        )}
                      </div>
                  )}
                  <div className="result-image-wrap">
                  {passPersonality === true ? (
                      <img src="/pass-personality.svg" className="image-summary" alt="summary-banner"  width="180" height="auto"/>
                    ) : (
                      <div>
                        {passPersonality === false ? (
                          <img src="/fail-personality.svg" className="image-summary" alt="summary-banner"  width="180" height="auto"/>
                        ) : (
                            <div>
                            </div>
                        )}
                      </div>
                  )}
                  {/* {passPersonality === true ? (
                      <img src="/pass-personality.svg" className="image-summary" alt="summary-banner"  width="250" height="auto"/>
                    ) : (
                      <img src="/fail-personality.svg" className="image-summary" alt="summary-banner"  width="250" height="auto"/>
                  )} */}
                  </div>
                </div>
              </div>
            <div className="result-subdescription result-subdescription-report">

              <p className="result-text result-report">{t("REPORT")}</p>
            </div>
          </div>

          <div className="result-description-white">
            <div className="result-subdescription">
              <p className="result-text">* {description1}</p>
            </div>
          </div>

          <div className="result-description">
            <div className="result-subdescription">
              <p className="result-text">* {description2}</p>
            </div>
          </div>

          <div className="result-description-white">
            <div className="result-subdescription">
              <p className="result-text">* {description3}</p>
              {list1 ? (
                <ol>
                  <li className="result-text-li">{list1}</li>
                  {list2 ? (<li className="result-text-li">{list2}</li>) : (<div/>)}
                  {list3 ? (<li className="result-text-li">{list3}</li>) : (<div/>)}
                  {list4 ? (<li className="result-text-li">{list4}</li>) : (<div/>)}
                  {list5 ? (<li className="result-text-li">{list5}</li>) : (<div/>)}
                </ol>
                ) : (
                <div/>
              )}
            </div>
          </div>

          {description4 ? (
            <div className="result-description result-description-last">
              <div className="result-subdescription">
                <p className="result-text">* {description4}</p>
              </div>
            </div>
            ) : (
            <div/>
            )}
        </div>

        <div className="result-container learning-resources">
          <h2 className="result-main-title-2 learning-resources-title">{t("Recommended Course")}</h2>
          

          <div className="result-description">
            <div className="result-subdescription course-description">
              <a href="https://codegym.cc/?ref=mweznji&tap_a=115829-efb074" target="_BLANK" rel="nofollow"><img src="https://static.tapfiliate.com/62cd742356829882733811.png?a=115829-efb074&s=5072154-823652" className="courseImage" border="0"/></a>
              {/* <h3>1. CodeGym:</h3> */}

              <p className="course-discount">{t("CodeGym is an online course for learning Java programming from scratch. This course is a perfect way to master Java for beginners. It contains 1200+ tasks with instant verification and an essential scope of Java fundamentals theory. To help you succeed in education, we’ve implemented a set of motivational features: quizzes, coding projects, content about efficient learning, and a Java developer’s career.")}</p>
              <p className="course-discount">{t("Click the link below to receive an additional discount, valid for the next 7 days only.")}</p>
              <div className="resource-link-wrap">
                <span>Link:</span> <a href="https://codegym.cc/?ref=mweznji" target="_BLANK" className="resource-link">CodeGym</a>
              </div>
            </div>
          </div>

          {/* <div className="result-description-white">
            <div className="result-subdescription">
              <h3>2. MDN Web Docs:</h3>

              <p>{t("Description: MDN Web Docs is a reliable source of information for all things related to web technologies. Maintained by Mozilla, this documentation offers a comprehensive view of HTML, CSS, JavaScript, and many other technologies, making it an excellent resource for every developer.")}</p>
              <div className="resource-link-wrap">
                <p>Link:</p> <a href="https://developer.mozilla.org/en-US/" className="resource-link">MDN Web Docs</a>
              </div>
            </div>
          </div>

          <div className="result-description">
            <div className="result-subdescription">
              <h3>3. Codecademy:</h3>

              <p>{t("Description: Codecademy is an interactive learning platform that guides you through practical exercises and courses in programming. With a focus on various languages and technologies, Codecademy is a great choice for both beginners and those looking to enhance their skills.")}</p>
              <div className="resource-link-wrap">
                <p>Link:</p> <a href="https://www.codecademy.com/" className="resource-link">Codecademy</a>
              </div>
            </div>
          </div> */}
        </div>
        
        {/* <div className="result-thanks">
            <p>{t("Thank you for taking the test ‘is coding for you’")}.</p>
        </div> */}
      </div>
      <div className="result-social">
        <h2 className="social-title">{t("Send the test to your friends and let them try too")}</h2>
        <div className="social-icons">
          <a href="whatsapp://send?text=Is Coding For You. Online Test https://iscodingforyou.com">
            <img src="/social-wa.png" className="social-icon" alt="WA share test" width="50" height="50"/>
          </a>
          <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.iscodingforyou.com" target="_blank">
            <img src="/social-fb.png" className="social-icon" alt="WA share test" width="50" height="50"/>
          </a>
          <a href="https://twitter.com/intent/tweet?url=https://www.iscodingforyou.com&text=Is Coding For You. Online Test" target="_blank">
            <img src="/social-tw.png" className="social-icon" alt="WA share test" width="50" height="50"/>
          </a>
          <a href="https://www.linkedin.com/shareArticle?url=https://www.iscodingforyou.com&title=Is Coding For You. Online Test" target="_blank">
            <img src="/social-ln.png" className="social-icon" alt="WA share test" width="50" height="50"/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Result;
